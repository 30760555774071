import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import List from '../../../components/List';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { Namsan, NotoSerif } from '../../../components/Typography';
import {
  DrxContainer,
  DrxMeritList,
  DrxSection,
  FullWidthDrxSection,
  MobileDrxContainer,
} from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { drxMerits, mobileImageList, therapyDescription } from './data';
import { motion } from 'framer-motion';

const SpineDrxPage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 무중력 감압기</title>
        </Helmet>
        <PageTitle title="무중력 감압기" bgImage="spine" />

        <MobileDrxContainer>
          {mobileImageList.map((data, index) => (
            <div key={index}>
              {data.arr.map((image) => (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/spine/drx/${image}`}
                  alt=""
                />
              ))}
            </div>
          ))}
        </MobileDrxContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 무중력 감압기</title>
      </Helmet>
      <PageTitle title="무중력 감압기" bgImage="spine" />
      <DrxContainer>
        <DrxSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  척추디스크와 만성 통증을
                  <br />
                  위한 공간 척추교정
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              containerStyle={{
                gap: '100px',
              }}
              src="/images/spine/drx/image1.png"
              descriptionStyle={{
                display: 'flex',
                alignItems: 'center',
              }}
              description={
                <Card
                  containerStyle={{
                    flexDirection: 'column-reverse',
                    gap: '30px',
                  }}
                  titleAs="p"
                  titleStyle={{
                    color: '#D9D9D9',
                  }}
                  title={
                    <>
                      척추 견인법(Spinal Traction)을 활용하여 디스크에
                      <br />
                      가해지는 압력을 줄여 통증을 경감하고 회복을 촉진하는
                      <br />
                      의료기기입니다.
                      <br />
                      <br />
                      디스크의 퇴행성 변화로 인해 발생하는 교정 및 치료에
                      <br />
                      효과적이며 척추관협착증을 사전에 예방할 수 있습니다.
                    </>
                  }
                  paragraphAs={'h3'}
                  paragraphStyle={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '500',
                  }}
                  paragraph="무중력 감압기란?"
                />
              }
            />
          </motion.div>
        </DrxSection>
        <FullWidthDrxSection
          color="dark"
          style={{
            backgroundImage: 'url(/images/spine/drx/image2.png)',
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '750px',
          }}
        >
          <motion.div
            style={{
              width: '55%',
              height: '100%',
              backgroundColor: '#8261444D',
              paddingLeft: 'calc((100% - 1180px) / 2)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              containerStyle={{
                marginBottom: '50px',
              }}
              title="365일 척추관절 중점진료 에스본한의원"
              titleStyle={{
                fontWeight: 400,
                color: '#d9d9d9',
              }}
              paragraphStyle={{
                color: '#D2A780',
              }}
              paragraph={
                <>
                  무중력 감압기
                  <br />
                  MID ROBO MAX의 장점
                </>
              }
            />
            <DrxMeritList
              style={{
                color: '#ffffff',
                lineHeight: '33px',
              }}
            >
              {drxMerits.map((merit, index) => (
                <li key={index}>
                  <Namsan
                    style={{
                      color: '#ffffff',
                    }}
                  >
                    {merit}
                  </Namsan>
                </li>
              ))}
            </DrxMeritList>
          </motion.div>
        </FullWidthDrxSection>
        <DrxSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="무중력 감압기 치료 효과"
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              containerStyle={{
                gap: '100px',
              }}
              imageStyle={{
                maxWidth: '500px',
                maxHeight: '600px',
                aspectRatio: '500 / 600',
              }}
              src={'/images/spine/drx/image3.png'}
              descriptionStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              description={
                <>
                  <NotoSerif
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                    }}
                  >
                    치료 효과
                  </NotoSerif>
                  <List
                    style={{
                      gridTemplateColumns: '1fr',
                      marginTop: '30px',
                      marginBottom: '50px',
                    }}
                  >
                    {therapyDescription.map((therapy) => (
                      <li key={therapy}>
                        <img
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                          }}
                          src="/icons/check.svg"
                          alt=""
                        />
                        <Namsan
                          style={{
                            color: '#D9D9D9',
                          }}
                        >
                          {therapy}
                        </Namsan>
                      </li>
                    ))}
                  </List>
                  <NotoSerif
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                    }}
                  >
                    적용 질환
                  </NotoSerif>
                  <Table
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            목/허리 디스크
                          </Namsan>
                        </td>
                        <td>
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            척추관 협착증
                          </Namsan>
                        </td>
                        <td>
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            퇴행성 관절염
                          </Namsan>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            오십견
                          </Namsan>
                        </td>
                        <td>
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            스포츠 손상
                          </Namsan>
                        </td>
                        <td>
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            만성 통증
                          </Namsan>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              }
            ></ImageDescriptionBox>
          </motion.div>
        </DrxSection>
      </DrxContainer>
    </>
  );
};

export default SpineDrxPage;
