import styled from 'styled-components';
import { NotoSerif } from '../Typography';

export const FaqContainer = styled.ul`
  border: 1px solid #5d5d5d;
  border-top: none;
`;

export const FaqListItem = styled.li`
  min-height: 100px;
  padding-left: 38px;
  padding-right: 38px;
  border-top: 1px solid #5d5d5d;

  @media (max-width: 1080px) {
    min-height: 64px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FaqCheckIcon = styled.div`
  width: 42px;
  height: 42px;
  background-color: #826144;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (max-width: 1080px) {
    width: 25px;
    height: 25px;
    /* & img {
      width: 14px;
    } */
  }
`;

export const FaqListItemHeader = styled.div`
  display: flex;
  min-height: 100px;
  align-items: center;
  &:before {
    content: 'Q.';
    font-family: 'Noto Serif KR', serif;
    color: #5d5d5d;
    font-size: 30px;
    font-weight: 300;
    transform: translateY(-4px);
  }

  @media (max-width: 1080px) {
    min-height: 64px;

    & ${NotoSerif} {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
    }
    &:before {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const FaqListItemBody = styled.div``;
