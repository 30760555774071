import styled from 'styled-components';

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #00000066;
  backdrop-filter: blur(15px);
`;

export const RootLayoutContainer = styled.div``;
