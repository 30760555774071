import { Link } from 'react-router-dom';
import { MobileContainer, MobileSection } from '../../components/common';
import PageTitle from '../../components/PageTitle';
import { Namsan, NotoSerif } from '../../components/Typography';
import useWindowSize from '../../hooks/useWindowSize';
import { caseList, mobileImageList } from './data';
import { CaseContainer, CaseImage, CaseImageLink, CaseList, CaseSection } from './styles';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const CasePage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 치료 사례</title>
        </Helmet>
        <PageTitle title="치료 사례" bgImage="case" />
        <MobileContainer>
          <MobileSection
            style={{
              marginBottom: '50px',
            }}
          >
            {mobileImageList.map((item) => {
              if (item.href) {
                return (
                  <Link to={item.href} target="_blank">
                    <img
                      style={{
                        width: '100%',
                      }}
                      src={`/responsive/case/${item.img}`}
                      alt=""
                    />
                  </Link>
                );
              }

              return (
                <img
                  style={{
                    width: '100%',
                  }}
                  src={`/responsive/case/${item.img}`}
                  alt=""
                />
              );
            })}
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 치료 사례</title>
      </Helmet>
      <PageTitle title="치료 사례" bgImage="case" />
      <CaseContainer>
        <CaseSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <CaseList>
              {caseList.map((item) => (
                <li key={item.title}>
                  <CaseImageLink to={item.href} target="_blank">
                    <CaseImage src={item.image} alt="" />
                  </CaseImageLink>
                  <NotoSerif
                    style={{
                      color: '#D2A780',
                      display: 'block',
                      fontWeight: 500,
                      marginTop: '20px',
                    }}
                  >
                    {item.title}
                  </NotoSerif>
                  <Namsan
                    style={{
                      display: 'block',
                      color: '#A9A9A9',
                      fontSize: '16px',
                      marginTop: '2px',
                      marginBottom: '16px',
                    }}
                  >
                    {item.subtitle}
                  </Namsan>
                  <Namsan
                    style={{
                      color: '#d9d9d9',
                    }}
                  >
                    {item.description}
                  </Namsan>
                </li>
              ))}
            </CaseList>
          </motion.div>
        </CaseSection>
      </CaseContainer>
    </>
  );
};

export default CasePage;
