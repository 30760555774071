import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif, Pretendard } from '../../../components/Typography';
import { DoctorContainer, DoctorSection, MobileDoctorContainer } from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { motion } from 'framer-motion';

// const length = 3;
const mobileImageList = [
  ['1-1.svg', '1-2.png'],
  ['2-1.svg', '2-2.png', '2-3.svg'],
  ['3-1.svg', '3-2.png', '3-3.svg'],
];

const AboutDoctorPage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 원장소개</title>
        </Helmet>
        <PageTitle title="원장소개" bgImage="about" />

        <MobileDoctorContainer>
          {mobileImageList.map((arr, index) => (
            <div key={index}>
              {arr.map((image) => (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/about/doctor/${image}`}
                  alt=""
                />
              ))}
            </div>
          ))}
        </MobileDoctorContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 원장 소개</title>
      </Helmet>
      <PageTitle title="원장 소개" bgImage="about" />
      <DoctorContainer>
        <DoctorSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleAs={'h2'}
              paragraph="내 가족을 소개해 줄 수 있는 한의원"
              paragraphAs="p"
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              width: '100%',
              marginTop: '50px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              width={'100%'}
              src="/images/about/doctor/image1.png"
              alt="365일 척추관절 중점진료 에스본한의원"
            />
          </motion.div>
        </DoctorSection>
        <DoctorSection
          style={{
            gap: '50px',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleAs={'h3'}
              paragraph={
                <>
                  척추관절 질환, 정확하고
                  <br />
                  효율적으로 치료하겠습니다
                </>
              }
              paragraphAs="p"
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              src="/images/about/doctor/representative_doctor.png"
              alt="강인혜 대표원장"
              containerStyle={{
                gap: '120px',
              }}
              // descriptionStyle={{
              //   width: '280px',
              // }}
              description={
                <div
                  style={{
                    width: '310px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    lineHeight: '33px',
                  }}
                >
                  <NotoSerif
                    size="large"
                    $isPrimary
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                      marginBottom: '20px',
                    }}
                  >
                    강인혜 대표원장
                  </NotoSerif>
                  <Namsan
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    약력 및 활동
                  </Namsan>
                  <Namsan>
                    미국 근골격계 초음파사(RMSK) <br />
                    ARDMS 미국 진단 초음파협회 회원 <br />
                    국제모유수유전문가(IBCLC) <br />
                    동국대학교 석박사 통합과정 <br />
                  </Namsan>
                  <Namsan>
                    원광대학교 한의학과 졸업 <br />
                    대한침도의학회 정회원 <br />
                    대한한방비만학회 정회원 <br />
                    대한한방소아학회 정회원 <br />
                    한의기능영양학회 정회원 <br />
                  </Namsan>
                  <Namsan>
                    現)에스본한의원 대표원장 <br />
                    前)식품의약품안전처 연구원 <br />
                    前)함소아한의원 평촌점 원장 <br />
                    前)광덕안정한의원 구로디지털점 원장 <br />
                  </Namsan>
                </div>
              }
              imageStyle={{
                width: '680px',
                borderRadius: '24px',
              }}
            />
          </motion.div>
        </DoctorSection>
        <DoctorSection
          style={{
            gap: '50px',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleAs={'h3'}
              paragraph={
                <>
                  다이어트 진료는
                  <br />
                  누구보다 자신있습니다
                </>
              }
              paragraphAs="p"
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              src="/images/about/doctor/general_doctor.png"
              alt="박민정 원장"
              containerStyle={{
                gap: '120px',
              }}
              description={
                <div
                  style={{
                    width: '310px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    lineHeight: '33px',
                  }}
                >
                  <NotoSerif
                    size="large"
                    $isPrimary
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                      marginBottom: '20px',
                    }}
                  >
                    박민정 원장
                  </NotoSerif>
                  <Namsan
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    약력 및 활동
                  </Namsan>
                  <Namsan>
                    한방비만학회 정회원 <br />
                    대한약침학회 회원 <br />
                    대한침도의학회 회원 <br />
                  </Namsan>
                </div>
              }
              imageStyle={{
                width: '680px',
                borderRadius: '24px',
              }}
            />
          </motion.div>
        </DoctorSection>
      </DoctorContainer>
    </>
  );
};

export default AboutDoctorPage;
