import React, { useRef, useState } from 'react';
import { VideoThumbnail } from './styles';

const Video = () => {
  const ref = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const onClickPlay = () => {
    if (!ref.current) return;

    if (ref.current.paused) {
      ref.current.play();
    } else {
      ref.current.pause();
    }

    setIsPlaying(!ref.current.paused);
  };
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <video
        controls
        width="100%"
        ref={ref}
        onPause={() => {
          setIsPlaying(false);
        }}
      >
        <source src="/videos/spine/acupuncture/video1.mp4" type="video/mp4" />
        Download the
        <a href="/videos/spine/acupuncture/video1.mp4">MP4</a>
        video.
      </video>
      <VideoThumbnail onClick={onClickPlay} $isPlaying={isPlaying}>
        <img
          src="/icons/right-arrow-white.svg"
          style={{
            width: '50px',
            height: '50px',
          }}
          alt=""
        />
      </VideoThumbnail>
    </div>
  );
};

export default Video;
