import { MobileImageData } from '../../../types/types';

export const effectsList = [
  {
    title: '기억력 개선 및 치매 예방',
    source: '동의신경정신과학회지 v.15 No.2 2004년',
    paragraph:
      '알츠하이머형 치매환자에게 공진단 투여 전 K-DRS 총점의 평균은 93±12.1점으로 나타났고 공진단 투여 후 총점의 평균은 114± 14.5점으로 상슴됨을 알 수 있었다. 100일간의 공진단 투여가 치매 환자의 인지 기능을 향상시켰다.',
  },
  {
    title: '간 보호 효과',
    source: 'Thesis (doctoral) 한의학과 2016. 2 한무규',
    paragraph:
      'DSF 처리 그룹에서는 에탄올에 의한 간 손상이 유의미하게 완화된 것으로 나타났으며, 체중 변화와 간 무게 증가 역시 통계적으로 유의미한 차이를 보였습니다. 이러한 결과는 DSF가 알코올성 간질환 예방 및 치료에 유용하다고 볼 수 있다.',
  },
  {
    title: '급성 통증 완화 효과',
    source: '대한한방내과학회지 제 36권 제 3호 (2015. 9)',
    paragraph:
      '중증도의 급, 만성 통증환자에 대해 兩儀供辰丹을 병용한 한방치료의 진통효과를 고찰 한 후향적 연구로서 급성 및 만성의 구분없이 重 症의 통증에 유의한 호전을 보였으며, 진통제가 잘 듣지 않는 통증에도 효과가 있었다.',
  },
  {
    title: '항암 효과',
    source: '동의생리병리학회 v.17 no.6 2003년 은재순',
    paragraph:
      '사향과 Mitomycin C를 함께 처리할 때 대장암 세포의 성장 억제가 증가하며, 특히 Mitomycin C의 세포독성을 증대시켰습니다. 이는 사향이 항암제 Mitomycin C와 함께 사용될 경우 항암 효과를 강화할 수 있다.',
  },
  {
    title: '면역력 증진',
    source: '대한침구의학회지 16.3(99.11) 주정주',
    paragraph:
      '공진단 약침이 림프구와 CD4+T 세포의 수를 증가시키고 면역 기능을 개선하는 것으로 나타났습니다. 이는 공진단 약침이 면역 조절에 긍정적인 영향을 미칠 수 있으며, 한방 침구의학의 잠재적 임상적 활용 가능성이 있다.',
  },
  {
    title: '정력 강화',
    source: '대한한방부인과학회지 제 17권 제 1호 (2004.2) 이진무',
    paragraph:
      '양의궁신단 복용 후 생식 기능이 일부 회복되는 것을 발견했습니다. 양의궁신단이 노화로 인한 생식 능력 저하를 개선하는 잠재적 가능성이 있으며, 한방 보약이 생리하적 기능 저하에 대응하는데 효과가 있다.',
  },
];

export const suggestionsList = [
  {
    title: '체력 및 집중력 저하',
    description: (
      <>
        체력 및 집중력이 중요한 <br />
        수험생, 직장인 분들에게
        <br />
        권해드립니다
      </>
    ),
    image: '/images/medicine/gongjindan/list/image1.png',
  },
  {
    title: '면역력 저하',
    description: (
      <>
        면역력이 떨어져 감기나 피로에
        <br />
        자주 시달리시는 분들에게
        <br />
        권해드립니다
      </>
    ),
    image: '/images/medicine/gongjindan/list/image2.png',
  },
  {
    title: '만성 질환',
    description: (
      <>
        고혈압, 당뇨병, 관절염 등<br />
        만성 질환을 앓고 계신 분들에게
        <br />
        권해드립니다
      </>
    ),
    image: '/images/medicine/gongjindan/list/image3.png',
  },
  {
    title: '과도한 스트레스',
    description: (
      <>
        과도한 스트레스로 인해 신체적,
        <br />
        정신적 피로를 겪으시는 분들에게
        <br /> 권해드립니다
      </>
    ),
    image: '/images/medicine/gongjindan/list/image4.png',
  },
  {
    title: '수술 후 회복',
    description: (
      <>
        수술 후 회복되는 속도를
        <br />
        높이고 싶은 분들에게
        <br />
        권해드립니다',
      </>
    ),
    image: '/images/medicine/gongjindan/list/image5.png',
  },
  {
    title: '노화 예방',
    description: (
      <>
        노화 예방을 원하시는
        <br />
        중장년층 분들에게
        <br /> 권해드립니다',
      </>
    ),
    image: '/images/medicine/gongjindan/list/image6.png',
  },
];

export const mobileImageList: MobileImageData[] = [
  {
    arr: ['1-1.svg'],
  },
  {
    arr: ['2-1.svg'],
  },
  {
    arr: ['3-1.svg'],
  },
  {
    arr: ['4-1.svg', '4-2.png', '4-3.svg', '4-4.png', '4-5.svg'],
  },
];
