import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { Container } from '../../components/common';
import { NotoSerif, Roboto } from '../../components/Typography';

export const HomeMobileContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: 100px;
  & .swiper-button-prev,
  & .swiper-button-next {
    color: #fff;
  }
`;
export const HomeContainer = styled.div``;

export const HomeSection = styled.section`
  max-width: 1180px;
  margin: 200px auto;
`;

export const HomeDifferenceSection = styled(HomeSection)`
  display: flex;

  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

export const FullWidthHomeSection = styled.section`
  width: 100%;
  background-color: #222222;
  padding-top: 140px;
  padding-bottom: 140px;
`;

interface FullWidthImageDescriptionProps {
  $bgImgUrl?: string;
  defaultBgColor?: string;
}

export const FullWidthImageDescription = styled.section<FullWidthImageDescriptionProps>`
  width: 100%;
  background-color: #222222;
  // padding-top: 140px;
  // padding-bottom: 140px;
  display: flex;
  background-color: ${({ defaultBgColor }) => defaultBgColor ?? '#222222'};
  @media (max-width: 1060px) {
    background-color: #333333;
    background-blend-mode: overlay;
    background-image: ${({ $bgImgUrl }) => ($bgImgUrl ? `url(${$bgImgUrl})` : 'none')};
    background-position: center;
    & > div:first-child {
      display: none;
    }
  }
`;

export const HomeGradientSection = styled(FullWidthHomeSection)`
  background-image: url(/images/home/header_bg.png);
  background-position: right;
  height: 100dvh;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const HomeTableRow = styled.tr`
  margin-bottom: 32px;
`;

export const HomeFaqContainer = styled.ul`
  border: 1px solid #d9d9d9;
  border-top: none;
`;

export const HomeFaqListItem = styled.li`
  min-height: 100px;
  padding-left: 38px;
  padding-right: 38px;
  border-top: 1px solid #5d5d5d;

  @media (max-width: 1080px) {
    min-height: 64px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const HomeFaqListItemHeader = styled.div`
  display: flex;
  min-height: 100px;
  align-items: center;
  &:before {
    content: 'Q.';
    font-family: 'Noto Serif KR', serif;
    color: #cccccc;
    font-size: 30px;
    font-weight: 300;
    transform: translateY(-4px);
  }

  @media (max-width: 1080px) {
    min-height: 64px;

    & ${NotoSerif} {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
    }
    &:before {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const HomeFaqListItemBody = styled.div``;

export const ThumbImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ThumbSlide = styled(SwiperSlide)`
  opacity: 0.4;
  width: 200px;
  height: 150px;
  &.swiper-slide-thumb-active {
    opacity: 1;
  }

  @media (max-width: 1080px) {
    width: 146px;
    height: 110px;
  }
`;

export const HomeYoutubeWrapper = styled.div`
  background: #5a191e;
  width: 100%;
  max-width: 980px;
  aspect-ratio: 980 / 500;
  margin: 50px auto;
  position: relative;

  & > div {
    height: 100%;
  }
`;

export const HomePainSwiperIndicator = styled.div`
  position: absolute;
  bottom: 18px;
  right: 50px;
  font-size: 100px;
  z-index: 1;

  & > ${Roboto} {
    color: #ffffff;
    font-weight: 600;
    line-height: 25.6px;
    &:first-child {
      font-size: 26px;
    }
    &:last-child {
      font-size: 20px;
    }
  }
`;

export const HomeFaqCheckIcon = styled.div`
  width: 42px;
  height: 42px;
  background-color: #826144;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1080px) {
    width: 25px;
    height: 25px;
    /* & img {
      width: 14px;
    } */
  }
`;
