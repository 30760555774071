import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import { HorizontalDivider } from '../../../components/Divider';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import List from '../../../components/List';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif } from '../../../components/Typography';
import {
  FullWidthInsuranceSection,
  HealthInsuranceContainer,
  HealthInsuranceSection,
  MobileCoverageList,
} from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { MobileContainer, MobileSection } from '../../../components/common';
import { coverageList, insuranceInfo, mobileImageList } from './data';
import { motion } from 'framer-motion';

const MedicineHealthInsurancePage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 건강보험 첩약</title>
        </Helmet>
        <PageTitle title="건강보험 첩약" bgImage="medicine" />

        <MobileContainer>
          <MobileSection>
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/health-insurance/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection
            style={{
              // background: '#FAFAF8',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/medicine/health-insurance/2-1.svg`}
              alt=""
            />
            <img
              style={{
                width: '53%',
              }}
              src={`/responsive/medicine/health-insurance/2-2.png`}
              alt=""
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/medicine/health-insurance/2-3.svg`}
              alt=""
            />
          </MobileSection>
          <MobileSection>
            {mobileImageList[2].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/health-insurance/${image}`}
                alt=""
              />
            ))}
            <MobileCoverageList>
              {coverageList.map((coverage, index) => (
                <li>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={coverage.img}
                    alt=""
                  />
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={`/responsive/medicine/health-insurance/coverage/${index + 1}.svg`}
                    alt=""
                  />
                </li>
              ))}
            </MobileCoverageList>
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 건강보험 첩약</title>
      </Helmet>
      <PageTitle title="건강보험 첩약" bgImage="medicine" />
      <HealthInsuranceContainer>
        <HealthInsuranceSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  에스본한의원 첩약
                  <br />
                  건강보험 시범사업 선정
                </>
              }
              position="center"
              containerStyle={{
                marginBottom: '50px',
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              containerStyle={{
                gap: '100px',
              }}
              descriptionStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              description={
                <>
                  <Card
                    containerStyle={{
                      flexDirection: 'column-reverse',
                      maxWidth: '500px',
                    }}
                    paragraphStyle={{
                      color: '#D2A780',
                      fontSize: '28px',
                      fontWeight: 300,
                    }}
                    paragraph={'첩약 시범사업이란?'}
                    titleAs="p"
                    titleStyle={{
                      color: '#D9D9D9',
                      marginTop: '40px',
                      lineHeight: '33px',
                    }}
                    title={
                      <>
                        한약 치료 중 건강보험 적용 요구가 높은 첩약에 건강보험
                        <br />
                        시범 수가를 적용함으로써 국민 의료비 부담을 줄이고, 급여화를
                        <br />
                        통한 한의약 안전관리 체계 구축을 위해 실시된 사업입니다.
                        <br />
                        <br />
                        <Namsan as="strong" style={{
                            color: '#D2A780'
                          }}
                        >
                          2024년 4월 29일 ~ 2026년 12월까지 시범사업에 선정된 의료기관에 한하여
                          건강보험이 되는 첩약을 처방받을 수 있습니다.
                        </Namsan>
                      </>
                    }
                  />
                </>
              }
              imageStyle={{
                flexGrow: 1,
                flexShrink: 0,
              }}
              src="/images/medicine/health-insurance/image1.png"
            />
          </motion.div>
        </HealthInsuranceSection>
        <FullWidthInsuranceSection>
          <HealthInsuranceSection
            style={{
              alignItems: 'center',
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <ImageDescriptionBox
                containerStyle={{
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  gap: '100px',
                }}
                src="/images/medicine/health-insurance/image2.png"
                description={
                  <>
                    <Card
                      title="365일 척추관절 중점진료 에스본한의원"
                      paragraph={'첩약 건강보험 적용사항'}
                      paragraphStyle={{
                        color: '#333333'
                      }}
                    />
                    <List
                      style={{
                        gridTemplateColumns: '1fr',
                        marginTop: '40px',
                        marginBottom: '17px',
                      }}
                    >
                      {insuranceInfo.map((info, index) => (
                        <li key={info}>
                          <img
                            style={{
                              width: '30px',
                              height: '30px',
                              marginRight: '10px',
                            }}
                            src="/icons/check.svg"
                            alt="list"
                          />
                          <Namsan
                            style={{
                              color: index === 1 ? '#BB3030' : '#555555',
                            }}
                          >
                            {info}
                          </Namsan>
                        </li>
                      ))}
                    </List>
                    <Namsan
                      size="small"
                      style={{
                        color: '#767676',
                      }}
                    >
                      *2가지 질환 각각 연간 20일 이후 처방은 전액 본인부담
                    </Namsan>
                    <br />
                    <Namsan
                      as="strong"
                      size="small"
                      style={{
                        color: '#BB3030',
                      }}
                    >
                      *환자 전액 본인부담의 경우에도 실손의료보험 보상이 가능합니다.
                    </Namsan>
                  </>
                }
                imageStyle={{
                  flexGrow: 1,
                  flexShrink: 0,
                  maxHeight: '197px',
                  backgroundSize: 'contain',
                }}
              />
            </motion.div>
          </HealthInsuranceSection>
        </FullWidthInsuranceSection>
        <HealthInsuranceSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="첩약 건강보험 적용질환"
              containerStyle={{
                marginBottom: '50px',
              }}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <List
              style={{
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridGap: '20px',
              }}
            >
              {coverageList.map((coverage) => (
                <li
                  style={{
                    flexDirection: 'column',
                  }}
                  key={coverage.title}
                >
                  <img width={'100%'} src={coverage.img} alt={coverage.title} />
                  <div
                    style={{
                      border: '1px solid #5d5d5d',
                      borderTop: 'none',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      minHeight: '337px',
                    }}
                  >
                    <NotoSerif
                      as="h3"
                      style={{
                        display: 'block',
                        marginTop: '55.5px',
                        color: '#D2A780',
                        fontWeight: 500,
                      }}
                    >
                      {coverage.title}
                    </NotoSerif>
                    <HorizontalDivider
                      style={{
                        backgroundColor: '#D2A780',
                        width: '45px',
                        height: '2px',
                        margin: '30px 0',
                      }}
                    />
                    <Namsan
                      as="p"
                      style={{
                        lineHeight: '33px',
                        color: '#d9d9d9',
                        letterSpacing: '-0.02em',
                        textAlign: 'center',
                      }}
                    >
                      {coverage.description}
                    </Namsan>
                  </div>
                </li>
              ))}
            </List>
          </motion.div>
        </HealthInsuranceSection>
      </HealthInsuranceContainer>
    </>
  );
};

export default MedicineHealthInsurancePage;
