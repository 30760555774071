import styled from 'styled-components';
import { Container, Section } from '../../components/common';
import List from '../../components/List';
import { Link } from 'react-router-dom';

export const CaseContainer = styled(Container)``;

export const CaseSection = styled(Section)``;

export const CaseList = styled(List)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 56px;

  & li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 33px;
  }
`;

export const CaseImageLink = styled(Link)`
  position: relative;
  aspect-ratio: 380 / 275;
  &:after {
    width: 100%;
    height: 100%;
    display: none;
    content: '자세히보기';
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    color: #ffffff;
    font-family: 'Pretendard', sans-serif;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    opacity: 0.9;
    &:after {
      display: flex;
    }
  }
`;

export const CaseImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
