import styled from 'styled-components';

interface AccordionProps {
  $isOpen?: boolean;
}

export const AccordionContainer = styled.div``;

export const AccordionHeader = styled.div<AccordionProps>`
  & svg {
    transition: all 0.3s ease;
    transform: ${({ $isOpen }) => ($isOpen ? `rotate(0deg)` : 'rotate(180deg)')};
  }
`;

export const AccordionContent = styled.div<AccordionProps>`
  transition: all 0.5s ease;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  @starting-style {
    opacity: 0;
  }
`;
