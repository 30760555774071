import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { ModalClose, ModalContent, ModalOverlay } from './styles';
import { CSSProperties } from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  containerStyle?: CSSProperties;
}

const Modal = ({ isOpen, onClose, containerStyle, children }: PropsWithChildren<ModalProps>) => {
  if (!isOpen) return null;

  return createPortal(
    <ModalOverlay
      onClick={(e) => {
        onClose();
      }}
    >
      <ModalContent
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={containerStyle}
      >
        <ModalClose onClick={onClose}>
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src="/icons/close-black.svg"
            alt="close"
          />
        </ModalClose>
        {children}
      </ModalContent>
    </ModalOverlay>,
    document.body, // 모달을 body에 렌더링
  );
};

export default Modal;
