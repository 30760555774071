import React from 'react';
import {
  AuthForm,
  AuthInputContainer,
  AuthTitle,
  LoginButton,
  LoginContainer,
  LoginDivider,
} from '../styles';
import { useForm } from 'react-hook-form';
import {
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { authService } from '../../../utils/firebase/fbInstance';
import { kakaoLogin } from '../../../utils/oauth/kakao';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../../components/Input';
import { Pretendard } from '../../../components/Typography';
import { VerticalDivider } from '../../../components/Divider';
import Spinner from '../../../components/Spinner';
import PageTitle from '../../../components/PageTitle';

interface DefaultValue {
  email: string;
  password: string;
}

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DefaultValue>();
  const navigate = useNavigate();
  const onSubmit = async (data: DefaultValue) => {
    try {
      setPersistence(authService, browserSessionPersistence);

      const userCredential = await signInWithEmailAndPassword(
        authService,
        data.email,
        data.password,
      );
      const user = userCredential.user;
      if (user) {
        navigate('/');
      }
    } catch (error: any) {
      const errorCode = error.code;
      if (errorCode === 'auth/invalid-credential') {
        alert('이메일 또는 비밀번호가 올바르지 않습니다.');
      }
    }
  };

  return (
    <>
      <PageTitle title="로그인" bgImage="auth" />
      <LoginContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            padding: '0 16px',
          }}
        >
          <AuthTitle>간편가입을 통해 간단하게 로그인 하실 수 있습니다.</AuthTitle>
          <AuthForm
            onSubmit={handleSubmit(onSubmit)}
            style={{
              marginBottom: '24px',
            }}
          >
            <AuthInputContainer>
              <Input
                type="email"
                {...register('email', {
                  required: '이메일을 입력해주세요',
                })}
                error={errors.email?.message}
                placeholder="이메일"
              />
              <Input
                // label="비밀번호"
                type="password"
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                  minLength: {
                    message: '비밀번호는 6자 이상이어야 합니다.',
                    value: 6,
                  },
                })}
                error={errors.password?.message}
                placeholder="비밀번호"
                showPasswordEye
              />
            </AuthInputContainer>
            <LoginButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                    }}
                    src="/icons/loading-spinner.svg"
                    alt=""
                  />
                </Spinner>
              ) : (
                '로그인'
              )}
            </LoginButton>
          </AuthForm>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Link to="/find-pw">
              <Pretendard>비밀번호 찾기</Pretendard>
            </Link>
            <VerticalDivider
              style={{
                backgroundColor: 'gray',
                height: '10px',
                width: '2px',
                alignSelf: 'center',
              }}
            />
            <Link to="/signup">
              <Pretendard>회원가입</Pretendard>
            </Link>
          </div>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              position: 'relative',
              marginTop: '58px',
              marginBottom: '22px',
            }}
          >
            <LoginDivider>간편 로그인</LoginDivider>
          </div>
          <button onClick={kakaoLogin}>
            <img src="/icons/kakao.svg" alt="카카오 로그인하기" />
          </button>
        </div>
      </LoginContainer>
    </>
  );
};

export default LoginPage;
