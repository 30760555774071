import styled from 'styled-components';

export const DifferenceContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 200px;
`;

export const MobileDifferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 100px 16px;
`;

export const DifferenceSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
