import { CSSProperties, ReactNode } from 'react';
import { DescriptionBox, ImageBox, ImageDescriptionBoxContainer } from './styles';

export interface ImageDescriptionBoxProps {
  src?: string;
  alt?: string;
  img?: ReactNode;
  description?: ReactNode;
  containerStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  descriptionStyle?: CSSProperties;
}

const ImageDescriptionBox = ({
  src,
  alt,
  img,
  description,
  containerStyle,
  imageStyle,
  descriptionStyle,
}: ImageDescriptionBoxProps) => {
  return (
    <ImageDescriptionBoxContainer style={containerStyle}>
      {img ? (
        img
      ) : (
        <ImageBox $bgUrl={src} style={imageStyle}>
          <img src={src} alt={alt} />
        </ImageBox>
      )}
      <DescriptionBox style={descriptionStyle}>{description}</DescriptionBox>
    </ImageDescriptionBoxContainer>
  );
};

export default ImageDescriptionBox;
