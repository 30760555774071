import styled from 'styled-components';
import { Container, FullWidthSection, Section } from '../../components/common';
import List from '../../components/List';

export const DizzyContainer = styled(Container)`
  align-items: center;
  gap: 200px;
`;

export const DizzySection = styled(Section)`
  align-items: center;
  width: 100%;
`;

export const FullWidthDizzySection = styled(FullWidthSection)`
  height: auto;
`;

export const DizzySymptomList = styled(List)`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  & li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const DizzyCauseList = styled(List)`
  width: 50%;
  grid-gap: 20px;
  & li {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
`;
