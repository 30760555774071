import styled from 'styled-components';

export const HorizontalDivider = styled.div.attrs({ role: 'separator' })`
  width: 100%;
  height: 1px;
  background: #5d5d5d;
  margin: 20px 0;
`;

export const VerticalDivider = styled.div.attrs({ role: 'separator' })`
  width: 3px;
  height: 100%;
  background: #5d5d5d;
  margin: 0 20px;
`;
