import { MobileImageData } from '../../../../types/types';

export const mobileImageList: MobileImageData[] = [
  {
    arr: ['1.svg'],
  },
  {
    arr: ['2.svg', '3.svg'],
  },
];
