import styled from 'styled-components';
import { Container, FullWidthSection, Section } from '../../components/common';
import List from '../../components/List';
import { SwiperSlide } from 'swiper/react';

export const HospitalizationContainer = styled(Container)`
  gap: 200px;
`;

export const HospitalizationSection = styled(Section)``;

export const FullWidthHospitalizationSection = styled(FullWidthSection)`
  height: auto;
`;

export const HospitalizationAfterEffectsList = styled(List)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  & li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 43px;
    min-width: 178px;
    min-height: 241px;
    border-radius: 24px;
    cursor: pointer;
    @media (max-width: 1300px) {
      min-width: 140px;
    }
  }
`;

export const HospitalizationList = styled(List)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 60px;
  & li {
    width: 100%;
    flex-direction: column;
    line-height: auto;
  }
`;

export const HospitalizationFaqContainer = styled.ul`
  border: 1px solid #5d5d5d;
  border-top: none;
`;

export const HospitalizationFaqListItem = styled.li`
  /* height: 100px; */
  min-height: 100px;
  padding-left: 38px;
  padding-right: 38px;
  border-top: 1px solid #5d5d5d;
`;

export const HospitalizationFaqListItemHeader = styled.div`
  display: flex;
  min-height: 100px;
  align-items: center;
  &:before {
    content: 'Q.';
    font-family: 'Noto Serif KR', serif;
    color: #5d5d5d;
    font-size: 30px;
    font-weight: 300;
    transform: translateY(-4px);
  }
`;

export const HospitalizationTab = styled.ul`
  display: flex;
`;

export const TabListItem = styled.li<{ selected?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 22px;
  cursor: pointer;
  &:before {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    display: inline-block;
    content: '';
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-image: url(/icons/check.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const TabListContent = styled.li``;

export const HospitalizationSwiperButtonBox = styled.div`
  display: flex;
  gap: 30px;
  height: 64px;
  justify-content: center;
  align-items: center;
  margin-top: 45px;

  @media (max-width: 1080px) {
    margin-top: 25px;
  }
`;

export const HospitalizationSwiperSlide = styled(SwiperSlide)`
  width: 980px;
  height: 630px;

  @media (max-width: 1080px) {
    width: 100%;
    height: auto;
  }
`;

export const MobileHospitalizationList = styled(List)`
  grid-column-gap: 10px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
  }
`;
