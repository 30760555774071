import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
`;

export default Spinner;

// export default Spinner;
