import { CSSProperties, ReactNode } from 'react';
import { Namsan, NotoSerif, Size } from '../Typography';
import { WebTarget } from 'styled-components';

interface CardProps {
  className?: string;
  containerStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  title?: ReactNode;
  titleAs?: WebTarget;
  paragraphAs?: WebTarget;
  paragraphStyle?: CSSProperties;
  paragraph?: ReactNode;
  position?: 'left' | 'right' | 'center';
  titleSize?: Size;
  paragraphSize?: Size;
}

const Card = ({
  containerStyle = {},
  className,
  title,
  titleSize,
  titleStyle = {},
  paragraph,
  paragraphStyle = {},
  titleAs,
  paragraphSize,
  paragraphAs,
  position = 'left',
}: CardProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...containerStyle,
      }}
      className={className}
    >
      <Namsan
        as={titleAs}
        size={titleSize}
        style={{
          color: '#A9A9A9',
          lineHeight: '32px',
          textAlign: position,
          ...titleStyle,
        }}
      >
        {title}
      </Namsan>
      <NotoSerif
        as={paragraphAs}
        size={paragraphSize ?? 'xlarge'}
        style={{
          lineHeight: '64px',
          marginTop: '8px',
          textAlign: position,
          ...paragraphStyle,
        }}
      >
        {paragraph}
      </NotoSerif>
    </div>
  );
};

export default Card;
