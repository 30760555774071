import { ReactNode, useState } from 'react';
import { AccordionContainer, AccordionContent, AccordionHeader } from './styles';

interface AccordionProps {
  header: ReactNode;
  initialOpen?: boolean;
  body?: ReactNode;
}

const Accordion = ({ header, body, initialOpen }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  return (
    <AccordionContainer
      onClick={() => {
        setIsOpen((prev) => !prev);
      }}
    >
      <AccordionHeader $isOpen={isOpen}>{header}</AccordionHeader>
      <AccordionContent $isOpen={isOpen}>{body}</AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
