import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import ElipseBox from '../../../components/EllipseBox';
import { Emphasis } from '../../../components/Emphasis/styles';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { Namsan, NotoSerif, Pretendard, Roboto } from '../../../components/Typography';
import {
  AcupunctureContainer,
  AcupunctureGrid,
  AcupunctureGridItem,
  AcupunctureOrder,
  AcupunctureSection,
  FullWidthAcupunctureSection,
  MobileAcupunctureContainer,
  MobileAcupunctureSection,
} from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { merits, mobileImageList, problems } from './data';
import Video from './Video';
import { motion } from 'framer-motion';

const SpineAcupuncturePage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 미네랄 약침</title>
        </Helmet>
        <PageTitle title="미네랄 약침" bgImage="spine" />
        <MobileAcupunctureContainer>
          {mobileImageList.map((item, index) => (
            <MobileAcupunctureSection key={index} style={item.style}>
              {item.arr.map((src) => (
                <img
                  key={src}
                  style={{
                    width: '100%',
                  }}
                  src={`/responsive/spine/acupuncture/${src}`}
                  alt=""
                />
              ))}
              {item.extra ? item.extra : null}
            </MobileAcupunctureSection>
          ))}
        </MobileAcupunctureContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 미네랄 약침</title>
      </Helmet>
      <PageTitle title="미네랄 약침" bgImage="spine" />
      <AcupunctureContainer>
        <AcupunctureSection
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '588px',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  미네랄약침을 활용하여
                  <br />
                  다음의 고민을 해결합니다
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '17px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            {problems.map((problem, index) => (
              <ElipseBox
                key={index}
                variant="outlined"
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: '30px',
                  paddingLeft: '47px',
                  borderColor: '#5d5d5d',
                }}
              >
                <AcupunctureOrder>
                  <Roboto
                    style={{
                      color: '#FFFFFF',
                    }}
                  >
                    {(index + 1).toString().padStart(2, '0')}
                  </Roboto>
                </AcupunctureOrder>
                <div>
                  <Namsan
                    size="large"
                    style={{
                      color: '#d9d9d9',
                      lineHeight: '36px',
                    }}
                  >
                    {problem.paragraph}
                  </Namsan>
                </div>
              </ElipseBox>
            ))}
            {/* <ElipseBox
              variant="outlined"
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '48px',
                paddingRight: '48px',

                gap: '30px',
              }}
            >
              <AcupunctureOrder>
                <Roboto
                  style={{
                    color: '#FFFFFF',
                  }}
                >
                  02
                </Roboto>
              </AcupunctureOrder>
              <div>
                <Namsan
                  size="large"
                  style={{
                    color: '#555555',
                  }}
                >
                  한의원, 정형외과, 신경외과 등 다양한 치료를 받아도 통증이 재발하시는 분
                </Namsan>
              </div>
            </ElipseBox>
            <ElipseBox
              variant="outlined"
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '48px',
                paddingRight: '48px',
                gap: '30px',
              }}
            >
              <AcupunctureOrder>
                <Roboto
                  style={{
                    color: '#FFFFFF',
                  }}
                >
                  03
                </Roboto>
              </AcupunctureOrder>
              <div>
                <Namsan
                  size="large"
                  style={{
                    color: '#555555',
                  }}
                >
                  수술 치료는 시간적, 금전적으로 부담이 되어 비수술 치료를 희망하시는 분
                </Namsan>
              </div>
            </ElipseBox> */}
          </motion.div>
        </AcupunctureSection>
        <FullWidthAcupunctureSection
          style={{
            height: 'auto',
            // paddingTop: '140px',
            // paddingBottom: '140px',
          }}
          color="dark"
        >
          <AcupunctureSection
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <Card
                titleStyle={{
                  color: '#a9a9a9',
                }}
                paragraphStyle={{
                  color: '#D2A780',
                }}
                title="365일 척추관절 중점진료 에스본한의원"
                paragraph={'미네랄약침이란?'}
              />
              <div
                style={{
                  lineHeight: '33px',
                  marginTop: '50px',
                }}
              >
                <Namsan
                  style={{
                    color: '#ffffff',
                  }}
                >
                  근육의 수축과 신경 전도에 사용되는 나트륨, 칼륨, 칼슘,
                  <br />
                  붕소, 코발트, 칼슘 등 한약재로 사용되던 광물 속의 미네랄을
                  <br />
                  정제한 약침입니다.
                </Namsan>
                <br />
                <br />
                <Namsan
                  style={{
                    color: '#ffffff',
                  }}
                >
                  삼투압에 의해 칼슘 농도의 재배치가 이루어지고{' '}
                  <Namsan
                    as="strong"
                    style={{
                      color: '#D2A780',
                    }}
                  >
                    약해진 신경,
                    <br />
                    관절 주변 구조물에 직접적으로 영양분을 공급
                  </Namsan>
                  하기 때문에
                  <br />
                  근골격계 치료에 특화된 약침입니다.
                </Namsan>
                <br />
                <br />
                <Namsan
                  style={{
                    color: '#ffffff',
                  }}
                >
                  *치료 호전율이 높은 대표적인 질환
                </Namsan>
              </div>
              <Table $borderColor="#222222">
                <tbody>
                  <tr>
                    <td>
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        목/허리 디스크
                      </Namsan>
                    </td>
                    <td>
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        척추관 협착증
                      </Namsan>
                    </td>
                    <td>
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        퇴행성 관절염
                      </Namsan>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        오십견
                      </Namsan>
                    </td>
                    <td>
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        스포츠 손상
                      </Namsan>
                    </td>
                    <td>
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        만성 통증
                      </Namsan>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </motion.div>
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxWidth: '50%',
                gap: '20px',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                delay: 0.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <img
                width={'580px'}
                height="400px"
                src="/images/spine/acupuncture/image3.png"
                alt=""
              />
              <img
                width={'580px'}
                height="400px"
                src="/images/spine/acupuncture/image4.png"
                alt=""
              />
            </motion.div>
          </AcupunctureSection>
        </FullWidthAcupunctureSection>
        <FullWidthAcupunctureSection
          color="dark"
          style={{
            height: 'auto',
          }}
        >
          <AcupunctureSection
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
            >
              <Card
                titleStyle={{
                  color: '#A9A9A9',
                }}
                title="365일 척추관절 중점진료 에스본한의원"
                paragraph={'+ 초음파 기기 활용'}
              />
              <div
                style={{
                  lineHeight: '33px',
                  marginTop: '40px',
                }}
              >
                <Namsan
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  미네랄 약침 치료는 심부에 약물을 주입하는 정교한 치료이기
                  <br />
                  때문에 초음파 기기를 필수적으로 활용하고 있습니다.
                </Namsan>
                <NotoSerif
                  style={{
                    display: 'block',
                    color: '#D2A780',
                    marginTop: '60px',
                  }}
                  size="large"
                >
                  *치료의 목적
                </NotoSerif>
                <br />
                <Namsan
                  style={{
                    color: '#d9d9d9',
                    display: 'block',
                    marginBottom: '15px',
                  }}
                >
                  미네랄약침을 주입할 때 발생하는 수압을 활용하여
                  <br />
                  
                  <span style={{
                      color: '#D2A780'
                    }}
                  >
                    신경을 압박하는 구조물을 박리하여 통증의 원인을 치료합니다.
                  </span>
                </Namsan>

                <Namsan
                  style={{
                    display: 'block',
                    marginBottom: '15px',
                  }}
                >
                  체내에 잔존하는 미네랄약침의 성분이 손상된 인대, 힘줄, 신경을
                  <br />
                  치료하고{' '}
                  <span style={{
                      color: '#D2A780'
                    }}
                  >
                    조직의 재생을 촉진하여 통증의 재발을 예방합니다.
                  </span>
                </Namsan>

                <Namsan
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  디스크 질환처럼 통증의 원인이 신경 압박에 기인하는 경우
                  <br />
                  <span style={{
                      color: '#D2A780'
                    }}
                  >
                    치료의 호전율이 매우 높은 편입니다.
                  </span>
                </Namsan>
              </div>
            </motion.div>
            <motion.div
              style={{
                flex: 1,
                maxWidth: '580px',
                width: '50%',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                delay: 0.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  marginBottom: '18px',
                }}
              >
                <div
                  style={{
                    width: '50%',
                    height: '50%',
                    position: 'relative',
                  }}
                >
                  <img
                    width="100%"
                    height="100%"
                    src="/images/spine/acupuncture/before.png"
                    alt="치료 전"
                  />
                  <Pretendard
                    size="large"
                    style={{
                      position: 'absolute',
                      color: '#ffffff',
                      textTransform: 'uppercase',
                      bottom: 5,
                      left: 0,
                      width: '100%',
                      textAlign: 'center',
                      lineHeight: 2.8,
                    }}
                  >
                    before
                  </Pretendard>
                </div>
                <div
                  style={{
                    width: '50%',
                    height: '50%',
                    position: 'relative',
                  }}
                >
                  <img
                    width="100%"
                    height="100%"
                    src="/images/spine/acupuncture/after.png"
                    alt="치료 후"
                  />
                  <Pretendard
                    size="large"
                    style={{
                      position: 'absolute',
                      color: '#ffffff',
                      textTransform: 'uppercase',
                      bottom: 5,
                      left: 0,
                      width: '100%',
                      textAlign: 'center',
                      lineHeight: 2.8,
                    }}
                  >
                    after
                  </Pretendard>
                </div>
              </div>
              <Video />
            </motion.div>
          </AcupunctureSection>
        </FullWidthAcupunctureSection>
        <FullWidthAcupunctureSection
          style={{
            backgroundImage: 'url(/images/about/doctor/image1.png)',
            backgroundBlendMode: 'overlay',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 'auto',
            paddingTop: '140px',
            paddingBottom: '140px',
          }}
          color="dark"
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleStyle={{
                color: '#d9d9d9',
              }}
              paragraph="미네랄약침 장점"
              paragraphStyle={{
                color: '#ffffff',
              }}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <AcupunctureGrid
              style={{
                margin: '50px auto 0',
                maxWidth: '920px',
                width: '100%',
              }}
            >
              {merits.map((merit, index) => (
                <AcupunctureGridItem key={index} data-order={index + 1} style={{}}>
                  <Namsan
                    style={{
                      color: '#ffffff',
                      lineHeight: '33px',
                    }}
                  >
                    {merit.paragraph}
                  </Namsan>
                </AcupunctureGridItem>
              ))}
            </AcupunctureGrid>
          </motion.div>
        </FullWidthAcupunctureSection>
        <AcupunctureSection
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '50px',
            width: '100%',
          }}
          color="light"
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  미네랄약침은
                  <br />
                  스테로이드가 아닙니다
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              maxWidth: '980px',
              width: '100%',
              aspectRatio: '980 / 500',
              backgroundImage: 'url(/images/spine/acupuncture/image2.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                display: 'none',
              }}
              src="/images/spine/acupuncture/image2.png"
              alt="순수 생약 성분으로 조제된 미네랄 약침"
            />
          </motion.div>
        </AcupunctureSection>
      </AcupunctureContainer>
    </>
  );
};

export default SpineAcupuncturePage;
