import styled from 'styled-components';

interface FullWidthSectionProps {
  color?: 'light' | 'dark';
}

export const CustomizedContainer = styled.div`
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 200px;
`;

export const CustomizedSection = styled.section`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FullWidthCustomizedSection = styled.section<FullWidthSectionProps>`
  height: calc(100vh - 90px);
  background-color: ${({ theme, color }) =>
    color === 'dark' ? theme.color.dark : theme.color.white};
`;

export const DiagnosisList = styled.ul`
  max-width: 1024px;
  width: 100%;
  display: grid;
  grid-gap: 56px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
  & li {
  }
`;
