import styled from 'styled-components';

interface ElipseBoxProps {
  variant: 'contained' | 'outlined';
}

// contained: 배경색 priamry, 텍스트색상 white
// outlined: 배경색 transparent, 텍스트색상 primary, border 1px solid primary
const ElipseBox = styled.div<ElipseBoxProps>`
  border-radius: 60px;
  padding: 20px;
  background-color: ${({ theme, variant }) =>
    variant === 'contained' ? theme.color.primary : 'transparent'};
  color: ${({ theme, variant }) =>
    variant === 'contained' ? theme.color.white : '#D2A780'};
  border-width: 1px;
  border-style: solid;
  // border-color: #5d5d5d;
  border-color: ${({ variant }) => 
    variant === 'contained' ? 'transparent' : '#5D5D5D'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ElipseBox;
