import styled from 'styled-components';

interface FullWidthSectionProps {
  color?: 'light' | 'dark';
}

export const DrxContainer = styled.div`
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 200px;
`;

export const MobileDrxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 100px 16px;
`;

export const DrxSection = styled.section`
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

export const FullWidthDrxSection = styled.section<FullWidthSectionProps>`
  height: calc(100vh - 90px);
  background-color: ${({ theme, color }) =>
    color === 'dark' ? theme.color.dark : theme.color.white};
`;

export const DrxMeritList = styled.ol`
  list-style: decimal;
  margin-inline-start: 1rem;

  & li::marker {
    font-size: 20px;
  }
`;
