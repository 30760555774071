import { MobileImageData } from '../../../types/types';
import Video from './Video';

export const merits = [
  {
    paragraph: (
      <>
        모든 관절 및 근막 부위를
        <br />
        치료할 수 있습니다
      </>
    ),
  },
  {
    paragraph: (
      <>
        시술 시간이 짧아서 <br />
        일상으로의 복귀가 <br />
        빠릅니다
      </>
    ),
  },
  {
    paragraph: (
      <>
        마취가 어려운 고령의
        <br /> 환자분들에게도 부담이 <br />
        적습니다
      </>
    ),
  },
  {
    paragraph: (
      <>
        통증의 원인을 치료하기
        <br /> 때문에 재발 가능성이
        <br /> 낮습니다
      </>
    ),
  },
  {
    paragraph: (
      <>
        손상된 조직에 영양을
        <br />
        공급하여 조직의 재생을
        <br />
        촉진합니다
      </>
    ),
  },
  {
    paragraph: (
      <>
        프롤로 치료 효과를
        <br />
        동반하기 때문에
        <br />
        회복속도가 빠릅니다
      </>
    ),
  },
];

export const problems = [
  {
    paragraph: (
      <>
        일시적인 통증 완화가 아닌 통증의
        <br />
        근본적인 원인을 치료하고 싶으신 분
      </>
    ),
  },
  {
    paragraph: (
      <>
        한의원, 정형외과, 신경외과 등 다양한
        <br />
        치료를 받아도 통증이 재발하시는 분
      </>
    ),
  },
  {
    paragraph: (
      <>
        수술 치료는 시간적, 금전적으로 부담이
        <br />
        되어 비수술 치료를 희망하시는 분
      </>
    ),
  },
];

export const mobileImageList: MobileImageData[] = [
  { arr: ['1.svg'] },
  {
    arr: ['2-1.svg', '2-2.png', '2-3.svg'],
    style: {
      background: '#222222',
    },
  },
  {
    arr: ['3-1.svg', '3-2.png', '3-3.svg', '3-4.png', '3-5.svg', '3-6.svg'],
    extra: <Video />,
  },
  {
    arr: ['4.svg'],
    style: {
      padding: '0',
    },
  },
  {
    arr: ['5-1.svg', '5-2.svg'],
    style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
    },
  },
];
