import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  updateProfile,
} from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { authService, db } from '../../../utils/firebase/fbInstance';
import { AuthForm, AuthInputContainer, AuthTitle, LoginButton, LoginContainer } from '../styles';
import Input from '../../../components/Input';
import Spinner from '../../../components/Spinner';
import PageTitle from '../../../components/PageTitle';
import { HorizontalDivider } from '../../../components/Divider';
import Terms from '../../../components/Terms';
import Checkbox from '../../../components/Checkbox';

interface DefaultValue {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  phone: string;
  agree: {
    terms: boolean;
  };
}

const SignupPage = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<DefaultValue>();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (user) {
        navigate('/');
      }
    });

    return unsubscribe;
  }, [navigate]);

  const onSubmit = async (data: DefaultValue) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'validate',
        message: '비밀번호가 일치하지 않습니다.',
      });
      return;
    }

    try {
      setPersistence(authService, browserSessionPersistence);

      const userCredential = await createUserWithEmailAndPassword(
        authService,
        data.email,
        data.password,
      );

      const user = userCredential.user;

      await updateProfile(user, {
        displayName: data.name,
      });

      // 유저 데이터 업데이트
      const usersRef = collection(db, 'users');

      await setDoc(
        doc(usersRef, user.uid),
        {
          email: data.email,
          phone: data.phone,
          agree: data.agree,
        },
        {
          merge: true,
        },
      );

      navigate('/');
    } catch (error: any) {
      const errorCode = error.code;
      if (errorCode === 'auth/email-already-in-use') {
        alert('이미 가입된 이메일입니다.');
        navigate('/login');
        return;
      }
      // const errorMessage = error.message;
    }
  };

  return (
    <>
      <PageTitle title="회원가입" bgImage="auth" />
      <LoginContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            maxWidth: '580px',
            width: '100%',
            padding: '0 16px',
          }}
        >
          <AuthTitle>의료법에 의거, 후기는 회원만 열람이 가능합니다.</AuthTitle>
          <AuthForm
            onSubmit={handleSubmit(onSubmit)}
            style={{
              marginBottom: '24px',
            }}
          >
            <AuthInputContainer>
              <Input
                type="email"
                {...register('email', {
                  required: '이메일을 입력해주세요',
                })}
                error={errors.email?.message}
                placeholder="이메일 형식으로 입력해 주세요."
                label="이메일"
              />
              <Input
                // label="비밀번호"
                type="password"
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                  minLength: {
                    message: '비밀번호는 6자 이상이어야 합니다.',
                    value: 6,
                  },
                })}
                error={errors.password?.message}
                placeholder="비밀번호"
                showPasswordEye
                label="비밀번호"
              />
              <Input
                // label="비밀번호"
                type="password"
                {...register('confirmPassword', {
                  required: '비밀번호를 입력해주세요',
                  minLength: {
                    message: '비밀번호는 6자 이상이어야 합니다.',
                    value: 6,
                  },
                })}
                error={errors.password?.message}
                showPasswordEye
                label="비밀번호 확인"
              />
              <HorizontalDivider
                style={{
                  background: '#D9D9D9',
                }}
              />
              <Input
                // label="비밀번호"
                type="text"
                {...register('name', {
                  required: '이름을 입력해주세요.',
                })}
                placeholder="홍길동"
                error={errors.password?.message}
                label="이름"
              />
              <Input
                type="text"
                {...register('phone', {
                  required: '전화번호를 입력해 주세요.',
                })}
                placeholder="숫자만 입력해 주세요."
                error={errors.password?.message}
                label="휴대폰 번호"
              />
              <div>
                <Terms />
                <Checkbox
                  {...register('agree.terms')}
                  label="(필수) 개인정보 수집 및 이용에 동의합니다"
                />
              </div>
            </AuthInputContainer>

            <LoginButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                    }}
                    src="/icons/loading-spinner.svg"
                    alt=""
                  />
                </Spinner>
              ) : (
                '회원가입'
              )}
            </LoginButton>
          </AuthForm>
        </div>
      </LoginContainer>
    </>
  );
};

export default SignupPage;
