import { hydrate, render } from 'react-dom';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';
import { ThemeProvider } from 'styled-components';
import { NavermapsProvider } from 'react-naver-maps';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root')!;
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <ThemeProvider theme={theme}>
//         <NavermapsProvider ncpClientId="lck7kjdj6f">
//           <GlobalStyles />
//           <App />
//         </NavermapsProvider>
//       </ThemeProvider>
//     </BrowserRouter>,
//     rootElement,
//   );
// } else {
render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <NavermapsProvider ncpClientId="ditzfoaavm">
        <GlobalStyles />
        <App />
      </NavermapsProvider>
    </ThemeProvider>
  </BrowserRouter>,
  rootElement,
);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
