import styled from 'styled-components';

interface FullWidthSectionProps {
  color?: 'light' | 'dark';
}

export const Container = styled.div`
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const FullWidthSection = styled.section<FullWidthSectionProps>`
  height: calc(100vh - 90px);
  background-color: ${({ theme, color }) =>
    color === 'dark' ? theme.color.dark : theme.color.white};
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 100px 0;
`;

export const MobileSection = styled.section`
  padding: 0 16px;
`;
