import { useState } from 'react';
import Card from '../../../components/Card';
import ElipseBox from '../../../components/EllipseBox';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import List from '../../../components/List';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif } from '../../../components/Typography';
import {
  CustomizedContainer,
  CustomizedSection,
  DiagnosisList,
  FullWidthCustomizedSection,
} from './styles';
import { Helmet } from 'react-helmet';
import { MobileContainer, MobileSection } from '../../../components/common';
import {
  diagnosisList,
  diseaseCategories,
  diseaseList,
  effectList,
  medicineInfo,
  mobileImageList,
} from './data';
import useWindowSize from '../../../hooks/useWindowSize';
import { motion } from 'framer-motion';

const MedicineCustomizedPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);

  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 맞춤 한약</title>
        </Helmet>
        <PageTitle title="맞춤 한약" bgImage="medicine" />

        <MobileContainer>
          <MobileSection>
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/customized/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection
            style={{
              background: '#222222',
            }}
          >
            {mobileImageList[1].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/customized/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection>
            {mobileImageList[2].arr.map((image, index) => {
              if (index % 2 === 1) {
                return (
                  <img
                    style={{
                      width: '200px',
                      height: '200px',
                      display: 'block',
                      margin: '0 auto',
                    }}
                    key={image}
                    src={`/responsive/medicine/customized/${image}`}
                    alt=""
                  />
                );
              }
              return (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/medicine/customized/${image}`}
                  alt=""
                />
              );
            })}
          </MobileSection>
          <MobileSection>
            {mobileImageList[3].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/customized/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 맞춤 한약</title>
      </Helmet>
      <PageTitle title="맞춤 한약" bgImage="medicine" />
      <CustomizedContainer>
        <CustomizedSection>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  단순한 보약이 아닙니다
                  <br />
                  증상을 개선하는 치료입니다
                </>
              }
              position="center"
              containerStyle={{
                marginBottom: '30px',
              }}
            />
            <Namsan
              style={{
                textAlign: 'center',
                lineHeight: '33px',
                color: '#d9d9d9',
              }}
            >
              환자의 증상에 맞는 약재와 성분을 사용하여
              <br />
              증상을 완화하고 신체의 자생력을 높여줄 수 있습니다
            </Namsan>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignSelf: 'stretch',
                gap: '20px',
                marginTop: '50px',
              }}
            >
              {diseaseCategories.map((category, index) => (
                <ElipseBox
                  key={category}
                  style={{
                    flex: 1,
                    cursor: 'pointer',
                  }}
                  variant={selectedCategory === index ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSelectedCategory(index);
                  }}
                >
                  <NotoSerif
                    style={{
                      color: 'inherit',
                    }}
                    size="large"
                  >
                    {category}
                  </NotoSerif>
                </ElipseBox>
              ))}
            </div>
            <ImageDescriptionBox
              containerStyle={{
                marginTop: '60px',
                gap: '100px',
                flexDirection: 'row-reverse',
              }}
              imageStyle={{
                maxWidth: '680px',
                maxHeight: '520px',
              }}
              src={diseaseList[selectedCategory].img}
              descriptionStyle={{
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
                paddingTop: '40px',
                flex: 1,
              }}
              description={
                <>
                  <NotoSerif
                    size="large"
                    style={{
                      color: '#D2A780',
                      lineHeight: 1.6,
                      fontWeight: 300,
                    }}
                  >
                    다음의 질환은
                    <br />
                    한약으로 잘 낫습니다
                  </NotoSerif>
                  <List
                    style={{
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    {diseaseList[selectedCategory].list.map((disease) => (
                      <li key={disease}>
                        <img
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                          }}
                          src="/icons/check.svg"
                          alt=""
                        />
                        <Namsan size="large">{disease}</Namsan>
                      </li>
                    ))}
                  </List>
                </>
              }
            />
          </motion.div>
        </CustomizedSection>
        <FullWidthCustomizedSection
          color="dark"
          style={{
            height: 'auto',
            // paddingTop: '140px',
            // paddingBottom: '140px',
          }}
        >
          <CustomizedSection
            style={{
              gap: '140px',
            }}
          >
            <motion.div
              style={{
                width: '100%',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <ImageDescriptionBox
                containerStyle={{
                  flexDirection: 'row-reverse',
                  gap: '100px',
                }}
                src="/images/medicine/customized/image2.png"
                description={
                  <>
                    <Card
                      containerStyle={{
                        marginBottom: '25px',
                      }}
                      title="365일 척추관절 중점진료 에스본한의원"
                      titleStyle={{
                        color: '#d9d9d9',
                      }}
                      paragraphStyle={{
                        color: '#D2A780',
                      }}
                      paragraph={'맞춤한약이란?'}
                    />
                    <Namsan
                      style={{
                        color: '#FFFFFF',
                        lineHeight: '33px',
                      }}
                    >
                      맞춤한약이란 개인의 체질과 체형에 맞추어
                      <br />몸 상태를 진단한 뒤 처방하는 한약을 일컫습니다.
                    </Namsan>
                    <List
                      style={{
                        marginTop: '50px',
                        gridTemplateColumns: '1fr',
                      }}
                    >
                      {medicineInfo.map((info) => (
                        <li key={info}>
                          <img
                            style={{
                              width: '30px',
                              height: '30px',
                              marginRight: '10px',
                            }}
                            src="/icons/check-outlined.svg"
                            alt=""
                          />
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            {info}
                          </Namsan>
                        </li>
                      ))}
                    </List>
                  </>
                }
                descriptionStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flex: 1,
                }}
              />
            </motion.div>
            <motion.div
              style={{
                width: '100%',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <ImageDescriptionBox
                containerStyle={{
                  flexDirection: 'row-reverse',
                  gap: '100px',
                }}
                src="/images/medicine/customized/image3.png"
                description={
                  <>
                    <Card
                      containerStyle={{
                        marginBottom: '25px',
                      }}
                      title="365일 척추관절 중점진료 에스본한의원"
                      titleStyle={{
                        color: '#d9d9d9',
                      }}
                      paragraphStyle={{
                        color: '#D2A780',
                      }}
                      paragraph={
                        <>
                          근골격계 질환에도
                          <br />
                          정말 효과가 좋나요?
                        </>
                      }
                    />
                    <Namsan
                      style={{
                        color: '#FFFFFF',
                        lineHeight: '33px',
                      }}
                    >
                      에스본한의원의 맞춤 한약은 인대, 연골, 근육 등
                      <br />
                      척추관절의 구성 성분을 강화하여 퇴행을 억제하고
                      <br />
                      질환의 개선을 돕는 효능이 있습니다.
                    </Namsan>
                    <List
                      style={{
                        marginTop: '50px',
                        gridTemplateColumns: '1fr',
                      }}
                    >
                      {effectList.map((info) => (
                        <li key={info}>
                          <img
                            style={{
                              width: '30px',
                              height: '30px',
                              marginRight: '10px',
                            }}
                            src="/icons/check-outlined.svg"
                            alt=""
                          />
                          <Namsan
                            style={{
                              color: '#ffffff',
                            }}
                          >
                            {info}
                          </Namsan>
                        </li>
                      ))}
                    </List>
                  </>
                }
                descriptionStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flex: 1,
                }}
              />
            </motion.div>
          </CustomizedSection>
        </FullWidthCustomizedSection>
        <CustomizedSection
          style={{
            alignItems: 'stretch',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  식품의약품 안전처 연구원
                  <br />
                  출신의 체계적인 맞춤한약 처방
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DiagnosisList
              style={{
                gridColumnGap: 0,
                margin: '50px auto 0',
                letterSpacing: '-0.02em',
              }}
            >
              {diagnosisList.map((diagnosis) => (
                <li
                  key={diagnosis.title}
                  style={{
                    width: '100%',
                  }}
                >
                  <img
                    style={{
                      display: 'block',
                      maxWidth: '300px',
                      width: '100%',
                      margin: '0 auto',
                    }}
                    src={diagnosis.img}
                    alt={diagnosis.title}
                  />
                  <Card
                    containerStyle={{
                      flexDirection: 'column-reverse',
                      alignItems: 'center',
                    }}
                    title={diagnosis.description}
                    titleStyle={{
                      color: '#D9D9D9',
                      textAlign: 'center',
                    }}
                    paragraphSize="large"
                    paragraphStyle={{
                      color: '#D2A780',
                    }}
                    paragraph={diagnosis.title}
                  />
                </li>
              ))}
            </DiagnosisList>
          </motion.div>
        </CustomizedSection>
        <CustomizedSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={'믿을 수 있는 한약 처방'}
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              marginTop: '50px',
              maxWidth: '980px',
              width: '100%',
              aspectRatio: '980 / 500',
              backgroundImage: 'url(/images/medicine/customized/image5.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                display: 'none',
              }}
              src="/images/medicine/customized/image5.png"
              alt="에스본한의원 맞춤 한약"
            />
          </motion.div>
        </CustomizedSection>
      </CustomizedContainer>
    </>
  );
};

export default MedicineCustomizedPage;
