export const dizzySymptoms = [
  {
    content: '구토감',
  },
  {
    content: (
      <>
        머리가 빙빙
        <br />
        도는 듯한 두통
      </>
    ),
  },
  {
    content: '균형 장애',
  },
  {
    content: '말이 어눌해짐',
  },
  {
    content: (
      <>
        걸을 때
        <br />
        땅이 울렁거림
      </>
    ),
  },
  {
    content: (
      <>
        돌아누울 때
        <br />
        어지러움
      </>
    ),
  },
];

export const dizzyCauses = [
  {
    title: '귀 문제로 인한 어지럼증',
    category: '귀 문제',
    content: (
      <>
        어지럼증의 약 80%는 신체의 평형 유지와 관련된 내이
        <br />
        기관의 문제입니다. 말초성 어지럼증이라 표현하며,
        <br />
        이석증과 메니에르병이 대표적인 말초성 어지럼증 질환입니다.
      </>
    ),
  },
  {
    title: '뇌 문제로 인한 어지럼증',
    category: '뇌 문제',
    content: (
      <>
        뇌신경계 혹은 뇌혈관계에 문제가 생겨 중추성 어지럼증이
        <br />
        발생할 수 있습니다. 자율신경 실조증, 뇌졸중, 뇌경색 등의
        <br />
        질환이 나타날 수 있기 때문에 정밀검사가 우선되어야 합니다.
      </>
    ),
  },
  {
    title: '내과적 문제로 인한 어지럼증',
    category: '내과적 문제',
    content: (
      <>
        저혈당, 기립성저혈압, 부정맥, 미주신경발작 등의 내과적 문제로
        <br />
        인하여 어지럼증이 발생할 수 있습니다. 내과적 문제로 인한
        <br />
        어지럼증은 기저질환을 진단하고 관리하는 것이 중요합니다.
      </>
    ),
  },
  {
    title: '심리적 문제로 인한 어지럼증',
    category: '심리적 문제',
    content: (
      <>
        과도한 스트레스로 인하여 자율신경기능에 문제가 생겨
        <br />
        어지럼증이 발생할 수 있습니다. 화병, 공황장애, 불안장애,
        <br />
        우울증 등과 같은 질환으로 이어질 수 있습니다.
      </>
    ),
  },
];

export const dizzyTreatments = [
  {
    title: '어지럼증의 원인을 정확하게 파악합니다',
  },
  {
    title: '재발을 억제하는 근본적인 치료',
  },
];
