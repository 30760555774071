import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { authService, db } from '../../../utils/firebase/fbInstance';
import { updatePassword, updateProfile, User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/Input';
import { useForm } from 'react-hook-form';
import { AuthForm, AuthInputContainer, LoginButton, LoginContainer } from '../styles';
import Spinner from '../../../components/Spinner';
import Terms from '../../../components/Terms';
import PageTitle from '../../../components/PageTitle';
import Checkbox from '../../../components/Checkbox';

interface UserData {
  email?: string;
  phone?: string;
  agree: {
    terms: boolean;
    // privacy: boolean;
    // smsMarketing: boolean;
    // emailMarketing: boolean;
  };
}

interface DefaultValue extends UserData {
  name: string;
  password?: string;
  confirmPassword?: string;
}

const MyPage = () => {
  const [user, setUser] = useState<User | null>();
  const [userData, setUserData] = useState<UserData>();
  // const [isDeleting, setIsDeleting] = useState(false);
  const isOidc = user?.providerData[0]?.providerId === 'oidc.kakao';

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<DefaultValue>();

  const onSubmit = async ({ name, password, confirmPassword, phone }: DefaultValue) => {
    if (!user) return;

    // 만약 약관만 동의하는거라면 비번 입력 없이 동의만 하도록 처리
    if (!userData?.agree?.terms && !isDirty) {
      try {
        const usersRef = collection(db, 'users');

        await setDoc(
          doc(usersRef, user.uid),
          {
            agree: {
              terms: true,
            },
          },
          {
            merge: true,
          },
        );
        alert('약관동의가 완료되었습니다.');
        navigate('/');
        return;
      } catch (error) {
        console.log(error);

        alert('에러발생');
      }
    }

    if (!isDirty) {
      alert('변경된 내용이 없습니다.');
      return;
    }

    try {
      if (!isOidc) {
        if (!password || !confirmPassword) {
          alert('비밀번호를 입력해주세요.');
          return;
        }

        if (password !== confirmPassword) {
          alert('비밀번호가 일치하지 않습니다.');
          return;
        }

        await updatePassword(user, password);
      }

      if (name) {
        await updateProfile(user, {
          displayName: name,
        });
      }

      const usersRef = collection(db, 'users');
      const obj: { phone?: string } = {};
      if (phone) obj.phone = phone;

      await setDoc(doc(usersRef, user.uid), obj, {
        merge: true,
      });

      alert('수정이 완료되었습니다.');
      navigate('/');
    } catch (error) {
      console.log(error);
      alert('에러발생');
    }
  };

  // const onClickDelete = async () => {
  //   if (!window.confirm('정말 탈퇴하시겠습니까?')) return;
  //   if (!user) return;
  //   // setIsDeleting(true);
  //   try {
  //     await deleteUser(user);
  //     alert('탈퇴가 완료되었습니다.');
  //     navigate('/');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/');
      } else {
        setUser(user);
      }
    });

    return unsubscribe;
  }, [navigate]);

  useEffect(() => {
    if (!user) return;
    const usersRef = collection(db, 'users');

    getDoc(doc(usersRef, user.uid))
      .then((doc) => {
        if (doc.exists()) {
          const data = doc.data() as UserData;
          setUserData(data);
          setValue('name', user.displayName || '');
          setValue('phone', data.phone);
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, [user, setValue]);

  return (
    <>
      <PageTitle title="마이 페이지" bgImage="auth" />
      <LoginContainer
        style={{
          alignItems: 'center',
          maxWidth: '1180px',
          width: '100%',
          margin: '0 auto',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <AuthForm
            onSubmit={handleSubmit(onSubmit)}
            style={{
              marginBottom: '24px',
            }}
          >
            <AuthInputContainer>
              {isOidc ? null : (
                <Input type="email" placeholder="이름" defaultValue={userData?.email || ''} />
              )}
              <Input
                type="text"
                {...register('name')}
                placeholder="이름"
                defaultValue={user?.displayName || ''}
              />
              {isOidc ? null : (
                <>
                  <Input
                    type="password"
                    {...register('password', {
                      minLength: {
                        message: '비밀번호는 6자 이상이어야 합니다.',
                        value: 6,
                      },
                    })}
                    placeholder="변경할 비밀번호"
                    error={errors.password?.message}
                    showPasswordEye
                  />
                  <Input
                    type="password"
                    {...register('confirmPassword', {
                      minLength: {
                        message: '비밀번호는 6자 이상이어야 합니다.',
                        value: 6,
                      },
                    })}
                    placeholder="변경할 비밀번호 확인"
                    error={errors.confirmPassword?.message}
                    showPasswordEye
                  />
                </>
              )}
              <Input
                type="tel"
                {...register('phone')}
                placeholder="전화번호"
                defaultValue={userData?.phone}
              />
              {userData?.agree?.terms === true ? null : (
                <>
                  <Terms />
                  <Checkbox
                    {...register('agree.terms')}
                    label="(필수) 개인정보 수집 및 이용에 동의합니다"
                  />
                </>
              )}
            </AuthInputContainer>
            <LoginButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                    }}
                    src="/icons/loading-spinner.svg"
                    alt=""
                  />
                </Spinner>
              ) : (
                '정보 수정'
              )}
            </LoginButton>
          </AuthForm>
        </div>
      </LoginContainer>
    </>
  );
};

export default MyPage;
