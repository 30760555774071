import { MobileImageData } from '../../../types/types';

export const diseaseList = [
  {
    category: '근골격계 질환',
    list: ['퇴행성 디스크', '퇴행성 관절염', '척추관 협착증', '오십견', '급성 / 만성 통증'],
    img: '/images/medicine/customized/disease/image1.png',
  },
  {
    category: '내과 질환',
    list: [
      '소화불량',
      '갱년기 증후군',
      '만성피로',
      '간기능 저하',
      '어지럼증',
      '수족냉증',
      '다한증',
      '천식',
    ],
    img: '/images/medicine/customized/disease/image2.png',
  },
  {
    category: '피부과 질환',
    list: ['건선', '두드러기'],
    img: '/images/medicine/customized/disease/image3.png',
  },
  {
    category: '비뇨기과 질환',
    list: ['발기부전', '성욕감퇴'],
    img: '/images/medicine/customized/disease/image4.png',
  },
];

export const diseaseCategories = diseaseList.map((disease) => disease.category);

export const medicineInfo = [
  '체질에 맞는 약재를 사용합니다.',
  '진단한 날 바로 처방합니다.',
  '나이에 따라 복용량과 방법을 다르게 처방합니다.',
];

export const effectList = [
  '염증 및 부종, 통증 완화',
  '뼈, 인대, 근육 강화',
  '관절 척추에 영양 공급',
  '면역력 및 기력 증진',
];

export const diagnosisList = [
  {
    title: '맥진기',
    description: (
      <>
        음허(陰虛), 양허(陽虛), 기허(氣虛),
        <br />
        혈허(血虛) 등의 상태를 확인하여
        <br />
        환자의 체질을 종합적으로 판단합니다
      </>
    ),
    img: '/images/medicine/customized/icon1.png',
  },
  {
    title: '간수치',
    description: (
      <>
        간수치 검사를 통해 간의 상태를 확인하고
        <br />
        간에 부담을 주지 않는 약재를 처방하여
        <br />
        부작용을 예방합니다
      </>
    ),
    img: '/images/medicine/customized/icon2.png',
  },
  {
    title: '복진',
    description: (
      <>
        복진을 통해 내부 장기 및 소화기 상태를
        <br />
        평가하여 한약 처방의 정확성을 높이고
        <br />
        개인 맞춤형 치료를 제공합니다
      </>
    ),
    img: '/images/medicine/customized/icon3.png',
  },
];

export const mobileImageList: MobileImageData[] = [
  {
    arr: [
      '1-1.svg',
      '1-2.png',
      '1-3.svg',
      '1-4.svg',
      '1-5.png',
      '1-6.svg',
      '1-7.svg',
      '1-8.png',
      '1-9.svg',
      '1-10.svg',
      '1-11.png',
      '1-12.svg',
    ],
  },
  {
    arr: ['2-1.svg', '2-2.png', '2-3.svg', '2-4.svg', '2-5.png', '2-6.svg'],
  },
  {
    arr: ['3-1.svg', '3-2.png', '3-3.svg', '3-4.png', '3-5.svg', '3-6.png', '3-7.svg'],
  },
  {
    arr: ['4-1.svg', '4-2.svg'],
  },
];
