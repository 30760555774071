import styled from 'styled-components';
import { Pretendard } from '../Typography';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 60px;
  outline: none;
  padding: 20px 32px;
  color: #9d9d9d;
  border: 1px solid #5d5d5d;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: #222222;

  &:focus-within {
    border-color: #dd0000;
  }

  &::placeholder {
    color: #9d9d9d;
  }

  @media (max-width: 680px) {
    /* padding: 10px 20px; */
    height: 38px;
    padding: 9px 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
  }
`;

export const StyledLabelContainer = styled.label`
  position: relative;
  display: flex;
  gap: 50px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

export const StyledLabel = styled(Pretendard)`
  font-weight: 700;
  font-size: 16px;
  flex-basis: 90px;
  height: 60px;
  display: flex;
  align-items: center;
  align-self: flex-start;

  @media (max-width: 680px) {
    height: auto;
    flex-basis: auto;
    padding-bottom: 12px;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const StyledPasswordEye = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 50%);
  cursor: pointer;
  width: 30px;
  height: 30px;
  object-fit: cover;
  @media (max-width: 680px) {
    width: 24px;
    height: 24px;
    transform: translate(-50%, 35%);
  }
`;

export const StyledInputError = styled.span`
  display: block;
  margin-left: 32px;
  color: #bb2828;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;

  @media (max-width: 680px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
