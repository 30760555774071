import styled, { css } from 'styled-components';
import { Namsan, NotoSerif } from '../Typography';

export const PageTitleContainer = styled.div<{ $bgImage?: string }>`
  background-position: right;
  background-size: cover;
  ${({ $bgImage = '/images/common/header_bg.png' }) => css`
    background-image: url(/images/common/title/${$bgImage}.png);
  `}
  background-repeat: no-repeat;

  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 90px;
  }

  & > div {
    width: 100%;
    height: calc(100% - 90px);
    background-color: #00000066;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
      height: 100%;
    }
  }

  @media (max-width: 1000px) {
    &:before {
      height: 74px;
    }
  }

  @media (max-width: 768px) {
    height: 246px;
    ${({ $bgImage: bgImage = '/responsive/mobile_header_bg.png' }) => css`
      background-image: url(/images/common/title/${bgImage}-mobile.png);
    `}

    ${Namsan} {
      color: #a9a9a9 !important;
      font-weight: 300;
      font-size: 14px;
      line-height: 22.4px !important;
    }
    ${NotoSerif} {
      font-weight: 300;
      font-size: 24px;
      line-height: 38.4px !important;
    }

    &:before {
      background-color: #00000066;
      height: 89px;
    }
  }
`;
