import styled from 'styled-components';
import { Section, Container } from '../../../components/common';
import List from '../../../components/List';
import Table from '../../../components/Table';
import { Roboto } from '../../../components/Typography';

interface CircleProps {
  size: string;
  variant?: 'contained' | 'outlined';
}

export const DietContainer = styled(Container)`
  gap: 200px;
  align-items: center;
`;

export const DietSection = styled(Section)`
  margin: unset;
`;

export const DietList = styled(List)`
  margin-top: 50px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  min-height: 600px;
  letter-spacing: -0.02em;
`;

export const DietListItemLower = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;
`;

export const DietLiteItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow: hidden;
  transition: all 0.5s ease;
  text-align: center;
  padding: 90px 20px;
  transform: translateY(20%);

  & > ${Roboto}:first-child {
    opacity: 1;
    margin-bottom: 36px;
    transition: all 0.5s ease;
  }
`;

export const DietFigureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin-top: 54px;
`;

export const DietListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fafaf8;
  border-radius: 15px;
  overflow: hidden;

  &:hover {
    & ${DietLiteItemWrapper} {
      transform: translateY(-100px);
      overflow: visible;

      & > ${Roboto}:first-child {
        opacity: 0;
      }
    }

    ${DietListItemLower} {
      opacity: 1;
    }
  }
`;

export const DietOrderedList = styled.ol`
  list-style: decimal;
  margin-inline-start: 1rem;
  & li::marker {
    color: #ffffff;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const DietTable = styled(Table)`
  margin-top: 50px;
  & tr:nth-child(2) {
    background-color: ${({ theme }) => theme.color.dark};
    color: #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }
    
  & td {
    width: 25%;
    border: none;
    &:not(:last-of-type) {
      border-right: 1px solid #fff;
    }
  }
`;

export const Circle = styled.div<CircleProps>`
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, variant }) =>
    variant === 'contained' ? theme.color.primary : theme.color.white};
  border: 1px solid
    ${({ theme, variant }) => (variant === 'contained' ? theme.color.primary : '#D9D9D9')};
`;