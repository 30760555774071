import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSProperties } from 'react';
import { Namsan, NotoSerif, Pretendard } from '../../../components/Typography';

interface GongjindanSwiperProps {
  className?: string;
  style?: CSSProperties;
}

const theses = [
  {
    title: '기억력 개선 및 치매 예방',
    source: '동의신경정신과학회지 v.15 No.2 2004년',
    content: (
      <>
        “100일간의 공진단 투여가 치매 환자의 <br />
        인지 기능을 향상시켰다"
      </>
    ),

    image: '/images/medicine/gongjindan/theses/image1.png',
  },
  {
    title: '간 보호 효과',
    source: 'Thesis (doctoral) 한의학과 2016. 2 한무규',
    content: (
      <>
        “DSF가 알코올성 간질환 예방 및 치료에 <br />
        유용하다고 볼 수 있다.”
      </>
    ),
    image: '/images/medicine/gongjindan/theses/image2.png',
  },
  {
    title: '급성 통증 완화 효과',
    source: '대한한방내과학회지 제 36권 제 3호 (2015. 9)',
    content: (
      <>
        “급성 / 만성의 구분 없이 중증의 통증에 유의미한
        <br />
        호전을 보였으며, 진통제가 잘 듣지 않는 통증에도
        <br />
        효과가 있었다"
      </>
    ),
    image: '/images/medicine/gongjindan/theses/image3.png',
  },
  {
    title: '항암 효과',
    source: '동의생리병리학회 v.17 n0.6 2003년 은재순',
    content: (
      <>
        “사향이 항암제 Mitomycin C와 함께
        <br />
        사용될 경우 항암 효과를 강화할 수 있다.”
      </>
    ),
    image: '/images/medicine/gongjindan/theses/image4.png',
  },
  {
    title: '면역력 증진',
    source: '대한침구의학회지 16.3(99.11) 주정주',
    content: (
      <>
        “공진단 약침이 림프구와 CD4+T 세포의 수를
        <br />
        증가시키고 면역 기능을 개선하는 것으로
        <br /> 나타났다.”
      </>
    ),
    image: '/images/medicine/gongjindan/theses/image5.png',
  },
  {
    title: '정력 강화',
    source: '대한한방부인과학회지 제 17권 제 1호 (2004.2) 이진무',
    content: (
      <>
        “노화로 인한 생식 능력 저하를 개선하는 잠재적
        <br /> 가능성이 있으며, 한방 보약이 생리학적 기능 저하에
        <br /> 대응하는데 효과가 있다"
      </>
    ),
    image: '/images/medicine/gongjindan/theses/image6.png',
  },
];

const GongjindanSwiper = ({ className, style }: GongjindanSwiperProps) => {
  return (
    <Swiper
      slidesPerView={'auto'}
      loop={true}
      modules={[Pagination, Navigation, Autoplay]}
      className={className}
      style={style}
      grabCursor={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {theses.map((thesis) => (
        <SwiperSlide key={thesis.title}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div className="thesis-wrapper">
              <img src={thesis.image} alt="" />
              <div>
                <NotoSerif
                  style={{
                    color: '#ffffff',
                    fontWeight: 500,
                    lineHeight: '33px',
                  }}
                >
                  {thesis.title}
                </NotoSerif>
                <Pretendard
                  style={{
                    color: '#ffffff',
                    display: 'block',
                    marginTop: '5px',
                    marginBottom: '30px',
                  }}
                  size="medium"
                >
                  {thesis.source}
                </Pretendard>
                <Namsan
                  style={{
                    color: '#ffffff',
                    lineHeight: '33px',
                  }}
                >
                  {thesis.content}
                </Namsan>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default GongjindanSwiper;
