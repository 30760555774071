import type { DefaultColorTheme, DefaultSpacingTheme } from '../types/styled-components';

const color: DefaultColorTheme = {
  danger: 'red',
  primary: '#826144',
  dark: '#222222',
  secondary: 'darkorange',
  white: '#ffffff',
};

const spacing: DefaultSpacingTheme = {
  xs: '14px',
};

const theme = {
  color,
  spacing,
};
export default theme;
