import styled, { css } from 'styled-components';

// 20: xl
// 24: 2xl;
// 28: ???
export type Size = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
interface TypographyProps {
  size?: Size;
  $isPrimary?: boolean;
}

export const Namsan = styled.span<TypographyProps>`
  // size: 18, 20, 24,
  // weight: 400
  // 20 - 400
  // 18 - 400
  /* font-family: 'SeoulNamsan', sans-serif; */
  font-family: 'Pretendard', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: ${({ theme, $isPrimary }) => ($isPrimary ? theme.color.primary : '#ffffff')};
  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 14px;
      line-height: 24px;
    `}
  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 24px;
    `}
  ${({ size }) =>
    size === 'xlarge' &&
    css`
      font-size: 40px;
    `}
`;

export const NotoSerif = styled.span<TypographyProps>`
  // size: 24, 28, 40,
  // weight: 300, 500,
  // 24 - 300
  // 28 - 500
  // 40 - 300
  font-family: 'Noto Serif KR', serif;
  font-size: 24px;
  font-weight: 200;
  color: ${({ theme, $isPrimary: isPrimary }) => (isPrimary ? theme.color.primary : '#ffffff')};

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 28px;
    `}
  ${({ size }) =>
    size === 'xlarge' &&
    css`
      font-size: 40px;
    `}
`;

export const Pretendard = styled.span<TypographyProps>`
  // size: 16,
  // weight: 200,
  // 16 - 200
  font-family: 'Pretendard', sans-serif;
  font-size: 16px;
  font-weight: 200;
  color: ${({ theme, $isPrimary: isPrimary }) => (isPrimary ? theme.color.primary : '#ffffff')};

  ${({ size }) =>
    size === 'xlarge' &&
    css`
      font-size: 28px;
      font-weight: 400;
    `}
  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 18px;
      font-weight: 400;
    `}
  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 12px;
      font-weight: 300;
    `}
  ${({ size }) =>
    size === 'medium' &&
    css`
      font-size: 14px;
      font-weight: 300;
      line-height: 1.6;
    `}
`;

export const Roboto = styled.span<TypographyProps>`
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: ${({ theme, $isPrimary: isPrimary }) => (isPrimary ? theme.color.primary : '#ffffff')};

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 32px;
      font-weight: 400;
      line-height: 35px;
    `}
  ${({ size }) =>
    size === 'xlarge' &&
    css`
      font-size: 40px;
      font-weight: 400;
      line-height: 35px;
    `}
  ${({ size }) =>
    size === 'xxlarge' &&
    css`
      font-size: 64px;
      font-weight: 400;
      line-height: 55px;
    `}
`;

const Typography = {
  Namsan,
  NotoSerif,
  Pretendard,
};

export default Typography;
