import React, { useState } from 'react';
import Input from '../../../components/Input';
import { useForm } from 'react-hook-form';
import { sendPasswordResetEmail } from 'firebase/auth';
import { authService } from '../../../utils/firebase/fbInstance';
import {
  AuthForm,
  AuthInputContainer,
  FindEmailModalParagraph,
  FindEmailModalTitle,
  LoginButton,
  LoginContainer,
  ModalContainer,
  ModalImage,
} from '../styles';
import { Pretendard } from '../../../components/Typography';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import Modal from '../../../components/Modal';
import { getEmailParts } from '../../../utils/regex';
import PageTitle from '../../../components/PageTitle';

interface DefaultValue {
  name: string;
  phone: string;
  email: string;
}

const FindPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DefaultValue>();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailPart, setEmailPart] = useState<ReturnType<typeof getEmailParts>>(null);
  const onSubmit = (data: DefaultValue) => {
    sendPasswordResetEmail(authService, data.email)
      .then(() => {
        setEmailPart(getEmailParts(data.email));
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
  };

  return (
    <>
      <PageTitle title="비밀번호 찾기" bgImage="auth" />
      <LoginContainer
        style={{
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            maxWidth: '580px',
            width: '100%',
            padding: '0 16px',
          }}
        >
          <AuthForm
            onSubmit={handleSubmit(onSubmit)}
            style={{
              marginBottom: '24px',
            }}
          >
            <AuthInputContainer>
              <Input
                type="text"
                {...register('name', {
                  required: '이름을 입력해주세요.',
                })}
                error={errors.email?.message}
                placeholder="홍길동"
                label="이름"
              />
              <Input
                type="phone"
                {...register('phone', {
                  required: '전화번호를 입력해주세요.',
                })}
                error={errors.email?.message}
                placeholder="숫자만 입력해 주세요."
                label="휴대폰 번호"
              />
              <Input
                type="email"
                {...register('email', {
                  required: '이메일을 입력해주세요',
                })}
                error={errors.email?.message}
                placeholder="이메일 형식으로 입력해 주세요."
                label="이메일"
              />
            </AuthInputContainer>
            <LoginButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                    }}
                    src="/icons/loading-spinner.svg"
                    alt=""
                  />
                </Spinner>
              ) : (
                '비밀번호 재설정'
              )}
            </LoginButton>
          </AuthForm>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            navigate('/');
          }}
          containerStyle={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ModalContainer>
            <ModalImage
              style={{
                width: '60px',
                height: '60px',
              }}
              src="/icons/check-outlined-brown.svg"
              alt=""
            />
            <FindEmailModalTitle as="h4">이메일 전송 완료</FindEmailModalTitle>
            <FindEmailModalParagraph as="p">
              등록하신 {emailPart?.[1].substring(0, 3)}***@{emailPart?.[2]}으로
              <br />
              비밀번호 변경 링크를 전송드렸습니다.
              <br />
              이메일을 확인해 주세요.
            </FindEmailModalParagraph>
          </ModalContainer>
        </Modal>
      </LoginContainer>
    </>
  );
};

export default FindPasswordPage;
