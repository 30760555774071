import React, { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react';
import { CheckboxContainer, CheckboxInput, CheckboxLabel } from './styles';
import { CSSProperties } from 'styled-components';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
}

const Checkbox = (
  { label, type, labelStyle, inputStyle, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const id = useId();
  return (
    <CheckboxContainer>
      <CheckboxInput ref={ref} id={props.id || id} type={type} {...props} />
      <CheckboxLabel htmlFor={props.id || id}>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export default forwardRef(Checkbox);
