import { CSSProperties } from 'react';
import { Container as MapDiv, NaverMap, Marker } from 'react-naver-maps';

interface MapProps {
  mapStyle?: CSSProperties;
}

const Map = ({ mapStyle }: MapProps) => {
  return (
    <MapDiv
      style={{
        aspectRatio: '500 / 450',
        maxWidth: '500px',
        maxHeight: '450px',
        boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.15) ',
        marginTop: '20px',
        ...mapStyle,
      }}
    >
      <NaverMap defaultCenter={{ lat: 37.2849504, lng: 126.8179851 }} defaultZoom={15}>
        <Marker
          defaultPosition={{
            lat: 37.2849504,
            lng: 126.8179851,
          }}
        />
      </NaverMap>
    </MapDiv>
  );
};

export default Map;
