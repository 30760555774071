import { ReactNode } from 'react';
import { MobileImageData } from '../../../types/types';

export const mobileImageList: MobileImageData[] = [
  {
    arr: ['1-1.svg', '1-2.svg'],
  },
  {
    arr: ['2-1.svg'],
  },
  {
    arr: ['3-1.svg', '3-2.png', '3-3.svg', '3-4.png', '3-5.svg'],
  },
  {
    arr: ['4-1.svg', '4-2.png', '4-3.svg', '4-4.png', '4-5.svg'],
  },
];

interface Case {
  paragraph: ReactNode;
  before: string;
  after: string;
  gender: string;
  age: number;
  height: number;
  period: number;
  point: ReactNode;
}

export const caseList: Case[] = [
  {
    paragraph: (
      <>
        체중 때문인지 수시로 무릎이
        <br />
        아팠는데 감량 후 통증이 사라
        <br />
        지고 몸이 가벼워졌어요!
      </>
    ),
    before: '85.2kg',
    after: '67kg',
    gender: '여성',
    age: 32,
    height: 160,
    period: 9,
    point: (
      <>
        해당 환자분은 비만과 무릎 통증으로 내원해 주신 분입니다.
        <br />
        한약을 하루 3번씩 규칙적으로 섭취하시는 것을 권유드렸고
        <br />
        미네랄약침을 활용하여 무릎 신경을 치료하였습니다.
        <br />
        관리 9개월차 약 8kg 감량에 성공하셨습니다.
      </>
    ),
  },
  {
    paragraph: (
      <>
        식욕을 참지 못해서 매번 야식을
        <br />
        시켜 먹었는데, 한약 복용하고
        <br />
        습관이 개선되었어요!
      </>
    ),
    before: '74.3kg',
    after: '61.2kg',
    gender: '여성',
    age: 35,
    height: 164,
    period: 5,
    point: (
      <>
        해당 환자분은 식욕 억제가 힘들어서 내원하신 분입니다.
        <br />
        특히 취침 전에 식사 제한이 힘들었기 때문에 저녁약은 따로 공복감을
        <br />
        개선하기 위해 구분하여 처방하였습니다. 야식 습관을 완전히
        <br />
        개선하였고 관리 5개월차 약 13kg 감량에 성공하였습니다.
      </>
    ),
  },
  {
    paragraph: (
      <>
        40kg대로 체중 감량에
        <br />
        성공하였고 간수치까지
        <br />
        정상으로 돌아왔어요!
      </>
    ),
    before: '55.3Kg',
    after: '49.1kg',
    gender: '여성',
    age: 27,
    height: 157,
    period: 3,
    point: (
      <>
        해당 환자분은 높은 간수치와 약한 체력을 개선하기 위해 내원하신
        <br />
        분입니다. 간기능과 체력을 보하기 위한 한약재(구기자)의 함량을
        <br />
        높여서 처방하였으며, 약 3개월 차에 간수치가 정상으로 돌아왔습니다.
        <br />
        또한, 약 6kg 감량에 성공하였습니다.
      </>
    ),
  },
  {
    paragraph: (
      <>
        단기간에 체중감량을 하시려는
        <br />
        분들은 쿨쎄라 관리도 함께
        <br /> 받으시는걸 추천드려요!
      </>
    ),
    before: '80.6kg',
    after: '68kg',
    gender: '여성',
    age: 29,
    height: 167,
    period: 3,
    point: (
      <>
        해당 환자분은 전체적인 체중 감량과 더불어 하체 비만 및 옆구리살을
        <br />
        개선하기 위해 내원하신 분입니다. 다이어트 한약과 쿨쎄라 기기를
        <br />
        활용하여 관리 3개월 차에 약 12kg 감량에 성공하였습니다.
        <br />
        특히 하체비만이 눈에 띄게 개선되었습니다.
      </>
    ),
  },
];
