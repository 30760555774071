import styled from 'styled-components';
import { Section, Container, FullWidthSection } from '../../../components/common';
import List from '../../../components/List';
import { Namsan } from '../../../components/Typography';

export const GongjindanContainer = styled(Container)`
  gap: 200px;
  align-items: center;
`;

export const GongjindanSection = styled(Section)`
  margin: unset;
  width: 100%;
`;

export const ImageContainer = styled.div`
  max-width: 1180px;
  max-height: 500px;
  aspect-ratio: 1180 / 500;
  background-image: url(/images/medicine/gongjindan/image1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: '50px';
  display: flex;
  justify-content: end;
  position: relative;

  & .thesis-wrapper {
    z-index: 10;
    width: 62%;
    height: 100%;
    display: flex;
    align-items: center;
    & img {
      position: relative;
      height: calc(100% - 100px);
      transform: translateX(-50%);
    }
    & > div {
      transform: translateX(-20%);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 62%;
    height: 100%;
    background-color: #826144bf;
  }
`;

export const FullWidthGongjindanSection = styled(FullWidthSection)`
  background-color: #fafaf8;
  height: auto;
`;

export const GongjindanList = styled(List)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
    align-items: start;
    border: 1px solid #5d5d5d;
  }
`;

export const GongjindanDifferenceList = styled.ol`
  list-style: decimal;
  margin-inline-start: 1rem;
  & li::marker {
    color: #d9d9d9;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const GongjindanEffectParagraph = styled.div`
  overflow: hidden;

  ${Namsan} {
    transition: all 1s;
    transform: translateY(-100px);
    opacity: 0;
  }
`;

export const GongjindanDifferenceListItem = styled.li`
  padding: 37px 16px 27px;

  &:hover {
    & ${GongjindanEffectParagraph} ${Namsan} {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const GongjindanMobileList = styled(List)`
  grid-column-gap: 10px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
  }
`;
