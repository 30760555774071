import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import PageTitle from '../../../components/PageTitle';
import { Namsan, Pretendard, Roboto } from '../../../components/Typography';
import DietImageDescription from './DietImageDescription';
import {
  DietContainer,
  DietFigureWrapper,
  DietList,
  DietListItem,
  DietListItemLower,
  DietLiteItemWrapper,
  DietOrderedList,
  DietSection,
} from './styles';
import { Helmet } from 'react-helmet';
import { MobileContainer, MobileSection } from '../../../components/common';
import useWindowSize from '../../../hooks/useWindowSize';
import { caseList, mobileImageList } from './data';
import DietEffectSwiper from './DietEffectSwiper';
import { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { authService } from '../../../utils/firebase/fbInstance';
import { motion } from 'framer-motion';

const MedicineDietPage = () => {
  const { width } = useWindowSize();
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      setUser(user);
    });

    return unsubscribe;
  }, []);

  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 다이어트 한약</title>
        </Helmet>
        <PageTitle title="다이어트 한약" bgImage="medicine" />

        <MobileContainer>
          <MobileSection>
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/diet/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection>
            {mobileImageList[1].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/diet/${image}`}
                alt=""
              />
            ))}
            <DietEffectSwiper user={user} />
          </MobileSection>
          {mobileImageList.slice(2).map((item, index) => (
            <MobileSection key={index} style={item.style}>
              {item.arr.map((image) => (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/medicine/diet/${image}`}
                  alt=""
                />
              ))}
            </MobileSection>
          ))}
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 다이어트 한약</title>
      </Helmet>
      <PageTitle title="다이어트 한약" bgImage="medicine" />
      <DietContainer>
        <DietSection
          style={{
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  다이어트 성공률 1위는
                  <br />
                  한방 다이어트
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              marginTop: '50px',
              maxWidth: '1180px',
              maxHeight: '600px',
              height: '100%',
              width: '100%',
              // aspectRatio: '1180 / 500',
              aspectRatio: '1180 / 600',
              backgroundImage: 'url(/images/medicine/diet/image1.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              position: 'relative',
              marginBottom: '18px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <figure>
              <img
                style={{
                  display: 'none',
                }}
                src="/images/medicine/diet/image1.png"
                alt="1년간 3kg이상 체중 감량 성공률 1위인 한방 다이어트"
              />
              <figcaption
                style={{
                  display: 'none',
                  position: 'absolute',
                  bottom: '-20px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                출처 : 국민건강영양조사, 1년간 3kg 이상 체중감량 성공 기준
              </figcaption>
            </figure>
          </motion.div>
        </DietSection>
        <DietSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={'환자의 90%가 소개로 내원'}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietList as="ol">
              {caseList.map((item, index) => (
                <DietListItem key={index}>
                  <DietLiteItemWrapper>
                    <Roboto
                      size="xlarge"
                      style={{
                        color: '#555555',
                      }}
                    >
                      0{index + 1}
                    </Roboto>
                    <Namsan
                      style={{
                        color: '#555555',
                      }}
                    >
                      {item.paragraph}
                    </Namsan>
                    <DietListItemLower>
                      <DietFigureWrapper>
                        <Roboto size="large" $isPrimary>
                          {item.before}
                        </Roboto>
                        <img src="/icons/bottom-arrow.svg" />
                        <Roboto size="xxlarge" $isPrimary>
                          {item.after}
                        </Roboto>
                      </DietFigureWrapper>
                      <Button
                        style={{
                          marginTop: '60px',
                          width: '151px',
                        }}
                        hasRightArrow
                      >
                        <Link
                          to={`/medicine/diet/case/${index}`}
                          style={{
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '33px',
                          }}
                          onClick={(e) => {
                            if (!user) {
                              e.stopPropagation();
                              e.preventDefault();
                              alert('로그인이 필요한 서비스입니다.');
                              navigate('/login', {
                                replace: true,
                              });
                            }
                          }}
                        >
                          자세히 보기
                        </Link>
                      </Button>
                    </DietListItemLower>
                  </DietLiteItemWrapper>
                </DietListItem>
              ))}
            </DietList>
          </motion.div>
        </DietSection>
        <DietSection
          style={{
            gap: '100px',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  부작용 및 요요 없이
                  <br />한 달에 4kg 이상 감량 가능합니다
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietImageDescription
              src="/images/medicine/diet/image2.png"
              alt="첫번째 차별점, 간수치 검사"
              order={1}
              title={
                <Pretendard
                  style={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '500',
                  }}
                >
                  간수치 검사
                </Pretendard>
              }
              descriptionStyle={{
                marginTop: '100px',
              }}
              description={
                <Namsan
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  간의 전반적인 상태를 확인하고 환자에게 맞는
                  <br />
                  한약재를 선별하여 안전하게 한약을 조제합니다
                  <br />
                  <br />
                  다이어트 한약의 흡수율과 대사율을 높이고
                  <br />
                  간손상을 예방할 수 있습니다
                </Namsan>
              }
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietImageDescription
              src="/images/medicine/diet/image3.png"
              alt="두번째 차별점, 인바디 검사"
              order={2}
              title={
                <Pretendard
                  style={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '500',
                  }}
                >
                  인바디(InBody) 검사
                </Pretendard>
              }
              descriptionStyle={{
                marginTop: '100px',
              }}
              description={
                <Namsan
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  신체 내부의 생리학적 정보를 정확하게 분석하고
                  <br />
                  체계적인 다이어트 계획을 수립합니다.
                  <br />
                  <br />
                  식품의약품 안전처 연구원 출신 대표 원장이 직접
                  <br />
                  1:1 맞춤 다이어트 한약을 처방합니다.
                </Namsan>
              }
            />
          </motion.div>
        </DietSection>
        <DietSection
          style={{
            gap: '100px',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={'특정 부위의 지방이 고민이라면?'}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietImageDescription
              src="/images/medicine/diet/image4.png"
              alt="세번째 차별점, 쿨쎄라"
              order={3}
              title={
                <>
                  <Pretendard
                    style={{
                      color: '#D2A780',
                      fontSize: '28px',
                      fontWeight: '500',
                    }}
                  >
                    승마살, 복부, 팔뚝살 등
                    <br />
                    특정 부위의 지방층을 제거하는 시술
                  </Pretendard>
                  <Namsan
                    style={{
                      color: '#d9d9d9',
                      display: 'block',
                    }}
                  >
                    쿨쎄라(Coolshaping)
                  </Namsan>
                </>
              }
              descriptionStyle={{
                marginTop: '100px',
              }}
              description={
                <DietOrderedList>
                  <li>
                    <Namsan>특정 부위의 지방 감소를 원하시는 분</Namsan>
                  </li>
                  <li>
                    <Namsan>반복되는 다이어트와 요요에 지치신 분</Namsan>
                  </li>
                  <li>
                    <Namsan>일상에 지장 없이 편하게 관리받고 싶으신 분</Namsan>
                  </li>
                </DietOrderedList>
              }
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietImageDescription
              src="/images/medicine/diet/image5.png"
              alt="네번째 차별점, 노블쉐이프"
              order={4}
              title={
                <>
                  <Pretendard
                    style={{
                      color: '#D2A780',
                      fontSize: '28px',
                      fontWeight: '500',
                    }}
                  >
                    셀룰라이트를 집중적으로 제거하는 시술
                  </Pretendard>

                  <Namsan
                    style={{
                      color: '#d9d9d9',
                      display: 'block',
                    }}
                  >
                    노블쉐이프(Noble Shape)
                  </Namsan>
                </>
              }
              descriptionStyle={{
                marginTop: '100px',
              }}
              description={
                <DietOrderedList>
                  <li>
                    <Namsan>전신 바디 관리와 피부의 탄력이 필요하신 분</Namsan>
                  </li>
                  <li>
                    <Namsan>특정 부위의 지방 축적 및 셀룰라이트가 고민이신 분</Namsan>
                  </li>
                  <li>
                    <Namsan>지방 감소와 더불어 근육통 완화가 필요하신 분</Namsan>
                  </li>
                </DietOrderedList>
              }
            />
          </motion.div>
        </DietSection>
      </DietContainer>
    </>
  );
};

export default MedicineDietPage;
