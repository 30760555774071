import { MobileImageData } from '../../../types/types';

export const insuranceInfo = [
  '환자 1인당 연간 2가지 질환 처방 가능',
  '질환별 첩약 최대 20일까지 처방 가능 (최대 총 40일)',
  '첩약 건강보험 본인부담률 30%',
  '개인 실손보험 가능',
];

export const coverageList = [
  {
    title: '알레르기 비염',
    description: (
      <>
        한약은 알레르기 비염의 주된 원인인
        <br />
        염증을 줄이고 면역 체계를 조절하여
        <br />
        코막힘, 콧물, 후각 감소 등의 알레르기
        <br />
        비염의 증상을 완화하는 효능이 있습니다
      </>
    ),
    img: '/images/medicine/health-insurance/icon1.png',
  },
  {
    title: '기능성 소화불량',
    description: (
      <>
        한약은 소화 효소의 분비를 촉진하고 위장
        <br /> 기능 및 체질을 개선하여 상복부 통증,
        <br />
        속쓰림, 더부룩함 등의 기능성 소화불량
        <br />
        증상을 완화하는 효능이 있습니다
      </>
    ),
    img: '/images/medicine/health-insurance/icon2.png',
  },
  {
    title: '월경통',
    description: (
      <>
        한약은 호르몬 균형을 조절하여 복부
        <br />
        통증, 허리 통증, 메스꺼움 등의 월경통
        <br />
        증상을 완화하는 효능이 있습니다
      </>
    ),
    img: '/images/medicine/health-insurance/icon3.png',
  },
  {
    title: '요추 추간판탈출증',
    description: (
      <>
        한약의 항염 작용을 통해 염증과 부종을
        <br /> 완화하여 요통, 방사통, 감각저하 등의
        <br /> 요추 추간판 탈출증 증상을
        <br /> 완화하는 효능이 있습니다
      </>
    ),
    img: '/images/medicine/health-insurance/icon4.png',
  },
  {
    title: '안면 신경마비',
    description: (
      <>
        한약은 손상된 신경을 회복하고 신경
        <br />
        세포의 재생을 촉진하여 눈꺼풀, 입꼬리
        <br />
        마비 등의 안면 신경마비 증상을
        <br />
        완화하는 효능이 있습니다
      </>
    ),
    img: '/images/medicine/health-insurance/icon5.png',
  },
  {
    title: '뇌혈관질환 후유증',
    description: (
      <>
        한약은 혈액의 점도를 낮추고 혈전 형성을
        <br />
        억제하여 인지 기능 저하, 언어 장애,
        <br />
        감각 이상 등의 뇌혈관질환 후유증
        <br />
        증상을 완화하는 효능이 있습니다
      </>
    ),
    img: '/images/medicine/health-insurance/icon6.png',
  },
];

export const mobileImageList: MobileImageData[] = [
  {
    arr: ['1-1.svg', '1-2.png', '1-3.svg'],
  },
  {
    arr: ['2.svg'],
    style: {
      background: '#FAFAF8',
    },
  },
  {
    arr: ['3-1.svg'],
  },
];
