import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Header, RootLayoutContainer } from './styles';
import QuickMenu from '../components/QuickMenu';

const RootLayout = () => {
  return (
    <RootLayoutContainer>
      <Header>
        <Navbar />
      </Header>
      <QuickMenu />
      <Outlet />
      <Footer />
    </RootLayoutContainer>
  );
};

export default RootLayout;
