import Card from '../../components/Card';
import ImageDescriptionBox from '../../components/ImageDescriptionBox';
import PageTitle from '../../components/PageTitle';
import { Namsan, NotoSerif, Pretendard } from '../../components/Typography';
import {
  FullWidthHospitalizationSection,
  HospitalizationAfterEffectsList,
  HospitalizationContainer,
  HospitalizationFaqContainer,
  HospitalizationFaqListItemHeader,
  HospitalizationList,
  HospitalizationSection,
  HospitalizationTab,
  MobileHospitalizationList,
  TabListItem,
} from './styles';
import HospitalizationSwiper from './HospitalizationSwiper';
import { HomeFaqCheckIcon, HomeFaqListItem } from '../Home/styles';
import Accordion from '../../components/Accordion';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import useWindowSize from '../../hooks/useWindowSize';
import {
  afterEffectsList,
  differencesList,
  faq,
  mobileFaq,
  mobileImageList,
  tabList,
} from './data';
import { MobileContainer, MobileSection } from '../../components/common';
import HospitalizationProcessSwiper from './HospitalizationProcessSwiper';
import {
  FaqCheckIcon,
  FaqContainer,
  FaqListItem,
  FaqListItemHeader,
} from '../../components/FaQ/styles';
import { ImageBox } from '../../components/ImageDescriptionBox/styles';
import { motion } from 'framer-motion';

const HospitalizationPage = () => {
  const { width } = useWindowSize();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const intervalRef = useRef<NodeJS.Timer>();
  const getAfterEffectIconUrl = (index: number) => {
    return `/images/hospitalization/aftereffects/icon${index + 1}${
      selectedIndex === index ? '-selected' : ''
    }.png`;
  };

  const getAfterEffectImageUrl = (index: number) => {
    return `/images/hospitalization/aftereffects/image${index + 1}.png`;
  };

  // useEffect(() => {
  //   intervalRef.current = setInterval(() => {
  //     setSelectedIndex((prev) => (prev + 1) % afterEffectsList.length);
  //   }, 5000);

  //   return () => {
  //     clearInterval(intervalRef.current);
  //   };
  // }, []);

  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 입원 클리닉</title>
        </Helmet>
        <PageTitle title="입원 클리닉" bgImage="hospitalization" />

        <MobileContainer>
          <MobileSection>
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/hospitalization/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection
            style={{
              background: '#222',
              // padding: '60px 16px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/hospitalization/2-1.svg`}
              alt=""
            />
            <HospitalizationSwiper />
          </MobileSection>
          <MobileSection>
            {mobileImageList[1].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/hospitalization/${image}`}
                alt=""
              />
            ))}
            <MobileHospitalizationList>
              {Array.from({ length: 6 }).map((_, index) => (
                <li key={index}>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={`/responsive/hospitalization/list/${index + 1}.png`}
                    alt=""
                  />
                  <Pretendard
                    style={{
                      fontSize: '14px',
                      lineHeight: '22.4px',
                      fontWeight: 300,
                      paddingTop: '10px',
                    }}
                  >
                    {differencesList[index].title}
                  </Pretendard>
                </li>
              ))}
            </MobileHospitalizationList>
          </MobileSection>
          <MobileSection>
            {mobileImageList[2].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/hospitalization/${image}`}
                alt=""
              />
            ))}
            <HospitalizationProcessSwiper />
          </MobileSection>
          <MobileSection>
            <FaqContainer>
              {mobileFaq.map(({ answer, initialOpen, question }, index) => (
                <FaqListItem key={index}>
                  <Accordion
                    header={
                      <FaqListItemHeader>
                        <NotoSerif
                          style={{
                            flex: 1,
                            textWrap: 'nowrap',
                          }}
                        >
                          {question}
                        </NotoSerif>
                        <FaqCheckIcon>
                          <svg
                            width="9"
                            height="5"
                            viewBox="0 0 9 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.14258 4.57153L4.57103 0.999988L0.999488 4.57153"
                              stroke="white"
                            />
                          </svg>
                        </FaqCheckIcon>
                      </FaqListItemHeader>
                    }
                    body={
                      <Namsan
                        as="p"
                        style={{
                          paddingLeft: '16px',
                          paddingBottom: '20px',
                          color: '#d9d9d9',
                          lineHeight: '22.4px',
                          fontSize: '14px',
                          textWrap: 'nowrap',
                        }}
                      >
                        {answer}
                      </Namsan>
                    }
                    initialOpen={initialOpen}
                  />
                </FaqListItem>
              ))}
            </FaqContainer>
          </MobileSection>
        </MobileContainer>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>에스본한의원 | 입원 클리닉</title>
      </Helmet>
      <PageTitle title="입원 클리닉" bgImage="hospitalization" />
      <HospitalizationContainer>
        <FullWidthHospitalizationSection
          style={{
            background: '#222',
            marginLeft: 'calc((100% - 1180px) / 2)',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  회복에만 집중할 수 있는
                  <br />
                  입원 클리닉
                </>
              }
              containerStyle={{
                marginBottom: '100px',
                maxWidth: '1180px',
              }}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              containerStyle={{
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                gap: '50px',
              }}
              img={
                <>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <ImageBox
                      $bgUrl={getAfterEffectImageUrl(index)}
                      style={{
                        maxWidth: '850px',
                        maxHeight: '700px',
                        width: '100%',
                        height: '100%',
                        aspectRatio: '850 / 700',
                        borderRadius: '24px',
                        display: index === selectedIndex ? 'block' : 'none',
                        alignSelf: 'end',
                      }}
                    >
                      <img src={getAfterEffectImageUrl(index)} alt="" />
                    </ImageBox>
                  ))}
                </>
              }
              descriptionStyle={{
                maxWidth: '574px',
                alignSelf: 'end',
              }}
              description={
                <>
                  <Card
                    containerStyle={{
                      flexDirection: 'column-reverse',
                      marginBottom: '40px',
                    }}
                    paragraph={afterEffectsList[selectedIndex].title}
                    paragraphSize="large"
                    paragraphAs={'h3'}
                    paragraphStyle={{
                      color: '#D2A780',
                      fontWeight: '500',
                    }}
                    titleAs={'p'}
                    titleStyle={{
                      color: '#D9D9D9',
                    }}
                    title={afterEffectsList[selectedIndex].subtitle}
                    position="left"
                  />
                  <Namsan
                    as="p"
                    style={{
                      lineHeight: '33px',
                      color: '#D9D9D9',
                      minHeight: '140px',
                    }}
                  >
                    {afterEffectsList[selectedIndex].content}
                  </Namsan>
                  <HospitalizationAfterEffectsList
                    style={{
                      marginTop: '100px',
                    }}
                  >
                    {afterEffectsList.map((afterEffect, index) => (
                      <li
                        key={index}
                        style={{
                          backgroundColor: index === selectedIndex ? '#826144' : '#F4F4F4',
                        }}
                        onClick={() => {
                          setSelectedIndex(index);
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '84px',
                            maxHeight: '63px',
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${getAfterEffectIconUrl(index)})`,
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          <img
                            style={{
                              display: 'none',
                            }}
                            src={getAfterEffectIconUrl(index)}
                            alt=""
                          />
                        </div>
                        <Card
                          containerStyle={{
                            flexDirection: 'column-reverse',
                          }}
                          position="center"
                          paragraph={afterEffect.bottomTitle}
                          paragraphStyle={{
                            fontSize: '24px',
                            color: index === selectedIndex ? '#ffffff' : '#333333',
                            lineHeight: '33px',
                            fontWeight: index === selectedIndex ? 400 : 500,
                            marginTop: '22px',
                          }}
                          titleStyle={{
                            color: index === selectedIndex ? '#ffffff' : '#555555',
                            fontSize: '14px',
                            fontWeight: index === selectedIndex ? 300 : 400,
                            lineHeight: '33px',
                            marginTop: '3px',
                          }}
                          title="입원 클리닉"
                        />
                      </li>
                    ))}
                  </HospitalizationAfterEffectsList>
                </>
              }
            />
          </motion.div>
        </FullWidthHospitalizationSection>
        <FullWidthHospitalizationSection
          color="dark"
          style={{
            // paddingTop: '140px',
            // paddingBottom: '140px',
            height: 'auto',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleStyle={{
                color: '#d9d9d9',
              }}
              paragraph="1인 / 2인 입원실"
              paragraphStyle={{
                color: '#D2A780',
              }}
              containerStyle={{
                marginBottom: '70px',
              }}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              delay: 0.3,
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <HospitalizationSwiper
              style={{
                width: '100%',
              }}
            />
          </motion.div>
        </FullWidthHospitalizationSection>
        <HospitalizationSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  에스본한의원 입원실의
                  <br />
                  특별한 점
                </>
              }
              position="center"
              containerStyle={{
                marginBottom: '50px',
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <HospitalizationList>
              {differencesList.map((difference) => (
                <li key={difference.title}>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={difference.image}
                    alt=""
                  />
                  <NotoSerif
                    style={{
                      display: 'block',
                      marginTop: '30px',
                      fontWeight: 300,
                    }}
                  >
                    {difference.title}
                  </NotoSerif>
                </li>
              ))}
            </HospitalizationList>
          </motion.div>
        </HospitalizationSection>
        <HospitalizationSection
          style={{
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  교통사고 치료비,
                  <br />
                  보험적용 가능합니다
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <HospitalizationTab>
              {tabList.map((tab, index) => (
                <TabListItem
                  onClick={() => {
                    setSelectedTab(index);
                  }}
                  selected={index === selectedTab}
                  key={tab}
                >
                  <Pretendard
                    style={{
                      color: index === selectedTab ? '#D2A780' : '#ffffff',
                      lineHeight: '44.8px',
                      fontWeight: 500,
                    }}
                  >
                    {tab}
                  </Pretendard>
                </TabListItem>
              ))}
            </HospitalizationTab>
            <div
              style={{
                display: 'flex',
                height: '4px',
                backgroundColor: '#D9D9D9',
              }}
            >
              <div
                style={{
                  width: `${selectedTab * 33.3333333}%`,
                  transition: 'all 0.5s ease',
                }}
              />
              <div
                style={{
                  width: '33.3%',
                  backgroundColor: '#826144',
                  height: '5px',
                }}
              />
            </div>
            <div>
              {Array.from({ length: 3 }).map((_, index) => (
                <img
                  style={{
                    width: '100%',
                    display: index === selectedTab ? 'inline' : 'none',
                  }}
                  key={index}
                  src={`/images/hospitalization/image${index + 1}.jpg`}
                  alt=""
                />
              ))}
            </div>
          </motion.div>
        </HospitalizationSection>
        <HospitalizationSection
          style={{
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="에스본한의원 FAQ"
              paragraph="대표 원장이 답변드립니다"
              position="center"
              containerStyle={{
                marginBottom: '50px',
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <HospitalizationFaqContainer>
              {faq.map(({ answer, initialOpen, question }, index) => (
                <HomeFaqListItem key={index}>
                  <Accordion
                    header={
                      <HospitalizationFaqListItemHeader>
                        <NotoSerif
                          style={{
                            flex: 1,
                          }}
                        >
                          {question}
                        </NotoSerif>
                        <div
                          style={{
                            width: '42px',
                            height: '42px',
                            backgroundColor: '#826144',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src="/icons/chevron-bottom.svg" alt="" />
                        </div>
                      </HospitalizationFaqListItemHeader>
                    }
                    body={
                      <Namsan
                        as="p"
                        style={{
                          lineHeight: '36px',
                          paddingLeft: '31px',
                          paddingBottom: '50px',
                          color: '#d9d9d9',
                        }}
                      >
                        {answer}
                      </Namsan>
                    }
                    initialOpen={initialOpen}
                  />
                </HomeFaqListItem>
              ))}
            </HospitalizationFaqContainer>
          </motion.div>
        </HospitalizationSection>
      </HospitalizationContainer>
    </>
  );
};

export default HospitalizationPage;
