import Card from '../Card';
import { PageTitleContainer } from './styles';

interface PageTitleProps {
  title: string;
  bgImage?: string;
}

const PageTitle = ({ title, bgImage }: PageTitleProps) => {
  return (
    <PageTitleContainer $bgImage={bgImage}>
      <Card
        containerStyle={{
          alignItems: 'center',
        }}
        titleStyle={{
          color: '#d9d9d9',
          lineHeight: '32px',
        }}
        title={'에스본한의원'}
        titleAs="p"
        paragraph={title}
        paragraphAs="h1"
        paragraphStyle={{
          color: '#ffffff',
          lineHeight: '60px',
          marginTop: '0',
        }}
      />
    </PageTitleContainer>
  );
};

export default PageTitle;
