import Swiper from 'swiper';
import Card from '../../../components/Card';
import { HorizontalDivider } from '../../../components/Divider';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif, Pretendard } from '../../../components/Typography';
import {
  GongjindanContainer,
  GongjindanSection,
  GongjindanList,
  ImageContainer,
  FullWidthGongjindanSection,
  GongjindanDifferenceList,
  GongjindanEffectParagraph,
  GongjindanDifferenceListItem,
  GongjindanMobileList,
} from './styles';
import GongjindanSwiper from './GongjindanSwiper';
import { Helmet } from 'react-helmet';
import useWindowSize from '../../../hooks/useWindowSize';
import { MobileContainer, MobileSection } from '../../../components/common';
import { effectsList, mobileImageList, suggestionsList } from './data';
import MobileThesesSwiper from './MobileThesesSwiper';
import { motion } from 'framer-motion';

const MedicineGongjindanPage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 공진단</title>
        </Helmet>
        <PageTitle title="공진단" bgImage="medicine" />

        <MobileContainer>
          <MobileSection
            style={{
              padding: '0',
            }}
          >
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                  padding: '0 16px',
                }}
                key={image}
                src={`/responsive/medicine/gongjindan/${image}`}
                alt=""
              />
            ))}
            <MobileThesesSwiper />
          </MobileSection>
          <MobileSection>
            {mobileImageList[1].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/gongjindan/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection>
            {mobileImageList[2].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/gongjindan/${image}`}
                alt=""
              />
            ))}
            <GongjindanMobileList
            // style={{
            //   gap: '10px',
            // }}
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <li key={index}>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={`/responsive/medicine/gongjindan/list/${index + 1}-1.png`}
                    alt=""
                  />
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={`/responsive/medicine/gongjindan/list/${index + 1}-2.svg`}
                    alt=""
                  />
                </li>
              ))}
            </GongjindanMobileList>
          </MobileSection>
          <MobileSection>
            {mobileImageList[3].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/gongjindan/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 공진단</title>
      </Helmet>
      <PageTitle title="공진단" bgImage="medicine" />
      <GongjindanContainer>
        <GongjindanSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  논문으로 입증된
                  <br />
                  공진단의 다양한 효능
                </>
              }
              position="center"
              containerStyle={{
                marginBottom: '50px',
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageContainer>
              <GongjindanSwiper />
            </ImageContainer>
          </motion.div>
          <GongjindanList
            style={{
              marginTop: '60px',
            }}
          >
            {effectsList.map((effect) => (
              <GongjindanDifferenceListItem key={effect.title}>
                <NotoSerif
                  style={{
                    color: '#D2A780',
                    fontWeight: 500,
                  }}
                >
                  {effect.title}
                </NotoSerif>
                <Namsan
                  size="small"
                  style={{
                    fontWeight: 300,
                    color: '#A9A9A9',
                  }}
                >
                  {effect.source}
                </Namsan>
                <HorizontalDivider
                  style={{
                    width: '45px',
                    height: '2px',
                    alignSelf: 'center',
                    backgroundColor: '#D2A780',
                    margin: '36px 0',
                  }}
                />
                <GongjindanEffectParagraph>
                  <Namsan
                    as="p"
                    style={{
                      lineHeight: '33px',
                      color: '#d9d9d9',
                      letterSpacing: '-0.03em',
                    }}
                  >
                    {effect.paragraph}
                  </Namsan>
                </GongjindanEffectParagraph>
              </GongjindanDifferenceListItem>
            ))}
          </GongjindanList>
        </GongjindanSection>
        <FullWidthGongjindanSection
          style={{
            width: '100%',
            paddingTop: '200px',
            paddingBottom: '200px',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              containerStyle={{
                maxWidth: '1180px',
                margin: '0 auto',
                paddingLeft: '100px',
                paddingRight: '100px',
                justifyContent: 'space-between',
              }}
              imageStyle={{
                maxWidth: '350px',
                maxHeight: '280px',
                aspectRatio: '350 / 280',
              }}
              src="/images/medicine/gongjindan/image3.png"
              descriptionStyle={{
                display: 'flex',
                flexDirection: 'column',
              }}
              description={
                <>
                  <Card
                    title="365일 척추관절 중점진료 에스본한의원"
                    paragraph={'황실의 보약 공진단'}
                    paragraphStyle={{
                      color: '#333333'
                    }}
                    containerStyle={{
                      marginBottom: '40px',
                    }}
                  />
                  <Namsan
                    style={{
                      color: '#555555',
                      lineHeight: '33px',
                    }}
                  >
                    “공진단은 날 때부터 허약한 경우라도 타고난 원기를 튼튼히 하여 <br />
                    신수를 오르게 하고 심화를 내리게 하므로 온갖 병을 막아준다.”
                  </Namsan>
                  <br />
                  <Namsan
                    style={{
                      color: '#555555',
                      lineHeight: '33px',
                    }}
                  >
                    피로가 사라지지 않고, 감각이 둔해진 것 같고,
                    <br />
                    머리가 맑지 않다면 공진단 복용 후 그 효과에 놀라실 것입니다.
                  </Namsan>
                </>
              }
            />
          </motion.div>
        </FullWidthGongjindanSection>
        <GongjindanSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="이런 분들에게 권해드립니다"
              containerStyle={{
                marginBottom: '50px',
              }}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <GongjindanList>
              {suggestionsList.map((suggestion) => (
                <li key={suggestion.title}>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={suggestion.image}
                    alt=""
                  />
                  <div
                    style={{
                      margin: '0 auto',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      maxWidth: '270px',
                      paddingTop: '39px',
                      paddingBottom: '39px',
                    }}
                  >
                    <NotoSerif
                      style={{
                        color: '#D2A780',
                        fontWeight: 500,
                      }}
                    >
                      {suggestion.title}
                    </NotoSerif>
                    <HorizontalDivider
                      style={{
                        width: '45px',
                        height: '2px',
                        alignSelf: 'center',
                        backgroundColor: '#D2A780',
                        margin: '36px 0',
                      }}
                    />
                    <Namsan
                      as="p"
                      style={{
                        lineHeight: '33px',
                        textAlign: 'center',
                      }}
                    >
                      {suggestion.description}
                    </Namsan>
                  </div>
                </li>
              ))}
            </GongjindanList>
          </motion.div>
        </GongjindanSection>
        <GongjindanSection
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <motion.div
            style={{
              flexShrink: 0,
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div
              style={{
                position: 'sticky',
                top: '160px',
              }}
            >
              <Card
                title="에스본한의원 차별점"
                paragraph={
                  <>
                    동의보감 그대로
                    <br />
                    “진짜 공진단"
                  </>
                }
                containerStyle={{
                  marginBottom: '30px',
                }}
              />
              <GongjindanDifferenceList
                style={{
                  lineHeight: '33px',
                }}
              >
                <li>
                  <Namsan
                    style={{
                      color: '#d9d9d9',
                    }}
                  >
                    식약청 인증, 100% 러시아산 정품 사향과 녹용 활용
                  </Namsan>
                </li>
                <li>
                  <Namsan
                    style={{
                      color: '#d9d9d9',
                    }}
                  >
                    국내산 청정 한약재인 당귀, 산수유 활용
                  </Namsan>
                </li>
                <li>
                  <Namsan
                    style={{
                      color: '#d9d9d9',
                    }}
                  >
                    전통 제조 방법으로 조제된 공진단 활용
                  </Namsan>
                </li>
              </GongjindanDifferenceList>
            </div>
          </motion.div>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '100px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
          >
            <div
              style={{
                maxWidth: '680px',
              }}
            >
              <img
                style={{
                  width: '100%',
                }}
                src="/images/medicine/gongjindan/image4.png"
                alt=""
              />
              <Card
                title="정품 사향에 첨가물 없이 제조한 원방 공진단"
                paragraphStyle={{
                  marginTop: '0',
                  color: '#D2A780',
                  fontSize: '28px',
                  fontWeight: 300,
                  lineHeight: '45px',
                }}
                paragraph="사향 공진단"
                containerStyle={{
                  marginTop: '40px',
                  marginBottom: '30px',
                }}
              />
              <Namsan
                style={{
                  lineHeight: '33px',
                }}
              >
                사향은 사향 노루 수컷의 향낭 속의 분비물을 건조한 것으로
                <br />
                매우 귀한 약재로 손꼽힙니다. 사향 공진단은 막힌 기혈을 원활히 하여
                <br />
                노폐물을 배출하는 효능이 있습니다.
              </Namsan>
            </div>
            <div
              style={{
                maxWidth: '680px',
              }}
            >
              <img
                style={{
                  width: '100%',
                }}
                src="/images/medicine/gongjindan/image5.png"
                alt=""
              />
              <Card
                title="정품 사향에 첨가물 없이 제조한 원방 공진단"
                paragraph="목향 공진단"
                paragraphStyle={{
                  marginTop: '0',
                  color: '#D2A780',
                  fontSize: '28px',
                  fontWeight: 300,
                  lineHeight: '45px',
                }}
                containerStyle={{
                  marginTop: '40px',
                  marginBottom: '30px',
                }}
              />
              <Namsan
                style={{
                  lineHeight: '33px',
                }}
              >
                사향과 마찬가지로 향을 이용해서 몸의 기혈 순환을 돕는 효능이 있습니다.
                <br />
                사향에 비해 상대적으로 구하기 쉬운 약재이기 때문에 금액적으로 부담이
                <br />
                적은 장점이 있습니다.
              </Namsan>
            </div>
          </motion.div>
        </GongjindanSection>
      </GongjindanContainer>
    </>
  );
};

export default MedicineGongjindanPage;
