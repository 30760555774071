import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSProperties, useRef } from 'react';
import { Pagination } from 'swiper/modules';
import { mobileImageList } from './data';
import { Link, useNavigate } from 'react-router-dom';
import CustomPagination from '../../../components/CustomPagination';
import { User } from 'firebase/auth';

interface HomeSwiperProps {
  className?: string;
  style?: CSSProperties;
  user: User | null;
}

const slidesLength = 4;

const DietEffectSwiper = ({ className, style, user }: HomeSwiperProps) => {
  const navigate = useNavigate();
  const paginationRef = useRef(null);
  const pagination = {
    el: paginationRef.current,
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        loop={true}
        className={className}
        style={style}
        pagination={pagination}
        modules={[Pagination]}
      >
        {Array.from({ length: slidesLength }).map((_, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: '280px',
              height: '433px',
            }}
          >
            <Link
              onClick={(e) => {
                if (!user) {
                  e.stopPropagation();
                  e.preventDefault();
                  alert('로그인이 필요한 서비스입니다.');
                  navigate('/login', {
                    replace: true,
                  });
                }
              }}
              to={`/medicine/diet/case/${index}`}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                }}
                src={`/responsive/medicine/diet/slides/${index + 1}.svg`}
                alt=""
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <CustomPagination ref={paginationRef} />
    </>
  );
};

export default DietEffectSwiper;
