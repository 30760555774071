import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { ButtonWrapper } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  hasRightArrow?: boolean;
}

const Button = ({ children, hasRightArrow, ...props }: PropsWithChildren<ButtonProps>) => {
  return (
    <ButtonWrapper {...props} $hasRightArrow={hasRightArrow}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;
