import styled from 'styled-components';

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  line-height: 36px;
  grid-column-gap: 80px;
  & li {
    display: flex;
    align-items: center;
    line-height: 43px;
  }
`;

export default List;
