import { MobileImageData } from '../../../types/types';

export const therapyDescription = [
  '디스크 공간(척추간)의 회복',
  '추간공의 확장으로 신경근의 이동 범위 증가',
  '전, 후종 인대의 재 신장으로 척추 위치의 정상화',
  'Diffusion Effect(확산효과) 수분의 제공/영양분 공급의 활성화',
];

export const drxMerits = [
  '단계적이고 전문적인 비수술적 척추 감압교정치료가 가능',
  '환자의 디스크 탈출의 종류와 방향에 따른 맞춤형 치료가 가능',
  'DKIS VACCUM - 혈류 유입 유도 및 재활치료 가능',
  '척추 주변 조직에 운동 효과를 전달하여 혈류량 증가 및 원인적 치료에 적합',
  '편측 교정이 요구되는 둔부 내 세밀한 치료를 제공',
  '인체 체형을 우선적으로 생각하여 설계한 인체구조학적 형상',
  '경추, 흉추, 요추 - 세 가지 동시 시술이 가능',
];

export const mobileImageList: MobileImageData[] = [
  { arr: ['1-1.svg', '1-2.png', '1-3.svg'] },
  { arr: ['2-1.svg', '2-2.png', '2-3.svg'] },
  { arr: ['3-1.svg', '3-2.png', '3-3.svg'] },
];
