import { Helmet } from 'react-helmet';
import Card from '../../../../components/Card';
import PageTitle from '../../../../components/PageTitle';
import { Pretendard, Roboto } from '../../../../components/Typography';
import { DietContainer, DietSection, DietTable, Circle } from '../styles';
import { useParams } from 'react-router-dom';
import { caseList } from '../data';
import useWindowSize from '../../../../hooks/useWindowSize';
import { MobileContainer, MobileSection } from '../../../../components/common';
import { mobileImageList } from './data';
import { motion } from 'framer-motion';

const DietCasePage = () => {
  const params = useParams();
  const slugIndex = parseInt(params.index || '0');
  const data = caseList[slugIndex] || caseList[0];

  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 감량 사례</title>
        </Helmet>
        <PageTitle title="감량 사례" bgImage="medicine" />

        <MobileContainer>
          {mobileImageList.map((item, index) => (
            <MobileSection key={index} style={item.style}>
              {item.arr.map((image) => (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/medicine/diet/case/${slugIndex}/${image}`}
                  alt=""
                />
              ))}
            </MobileSection>
          ))}

          <MobileSection>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  maxWidth: '380px',
                  width: '70%',
                  backgroundImage: `url(/images/medicine/diet/case/${slugIndex + 1}-1.png)`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  aspectRatio: '380 / 635',
                  position: 'relative',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#767676',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  <Roboto
                    size="large"
                    style={{
                      color: '#ffffff',
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: '25px',
                    }}
                  >
                    {data.before}
                  </Roboto>
                </div>
              </div>
              <img
                style={{
                  width: '24px',
                  height: '24px',
                  alignSelf: 'center',
                  display: 'block',
                  margin: '30px 0',
                }}
                src="/icons/bottom-arrow.svg"
                alt=""
              />

              <div
                style={{
                  maxWidth: '380px',
                  width: '70%',

                  backgroundImage: `url(/images/medicine/diet/case/${slugIndex + 1}-2.png)`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  aspectRatio: '380 / 635',
                  position: 'relative',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#826144',
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  <Roboto
                    size="large"
                    style={{
                      color: '#ffffff',
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: '25px',
                    }}
                  >
                    {data.after}
                  </Roboto>
                </div>
              </div>
            </div>
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 감량 사례</title>
      </Helmet>
      <PageTitle title="감량 사례" bgImage="medicine" />
      <DietContainer>
        <DietSection
          style={{
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  실제 감량 사례를
                  <br />
                  확인해 보세요
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietTable>
              <tbody
                style={{
                  tableLayout: 'fixed',
                  fontSize: '28px',
                  fontWeight: 400,
                }}
              >
                <tr>
                  <td>성별</td>
                  <td>나이</td>
                  <td>신장</td>
                  <td>관리기간</td>
                </tr>
                <tr
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  <td>{data.gender}</td>
                  <td>{data.age}세</td>
                  <td>{data.height}cm</td>
                  <td>{data.period}개월</td>
                </tr>
              </tbody>
            </DietTable>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '58px',
                marginTop: '80px',
              }}
            >
              <Circle size="180px" style={{
                backgroundColor: '#222222',
                borderColor: '#5d5d5d'
              }}>
                <Roboto size="large">{data.before}</Roboto>
              </Circle>
              <img src="/icons/right-arrow.svg" />
              <Circle variant="contained" size="280px">
                <Roboto
                  size="xxlarge"
                  style={{
                    color: '#ffffff',
                  }}
                >
                  {data.after}
                </Roboto>
              </Circle>
            </div>
          </motion.div>
        </DietSection>
        <DietSection
          style={{
            margin: '0 auto',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="감량 후기"
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              width: '100%',
              maxWidth: '907px',
              display: 'flex',
              flexDirection: 'column',
              gap: '90px',
              margin: '0 auto',
              marginTop: '108px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div
              style={{
                backgroundImage: 'url(/icons/balloon.png)',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                position: 'relative',
                maxWidth: '518px',
                aspectRatio: '986 / 633',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  width: '52%',
                  transform: 'translate(7%, -35%)',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '25.6px',
                }}
              >
                {data.paragraph}
              </p>
            </div>
            <div
              style={{
                backgroundColor: '#826144',
                borderRadius: '50px 0',
                maxWidth: '550px',
                aspectRatio: '550 / 250',
                display: 'flex',
                flexDirection: 'column',
                padding: '30px 20px 0 30px',
                alignSelf: 'end',
                width: '100%',
              }}
            >
              <Pretendard
                style={{
                  color: '#ffffff',
                  display: 'block',
                  marginBottom: '20px',
                  lineHeight: '40px',
                  fontSize: '28px',
                  fontWeight: 400,
                }}
                as="h4"
              >
                치료 포인트!
              </Pretendard>
              <Pretendard
                style={{
                  color: '#ffffff',
                  lineHeight: '30px',
                  fontSize: '18px',
                  fontWeight: 200,
                }}
                as="p"
              >
                {data.point}
              </Pretendard>
            </div>
          </motion.div>
        </DietSection>
        <DietSection
          style={{
            margin: '0 auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="감량 결과"
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              marginTop: '50px',
              maxWidth: '1180px',
              width: '100%',
              aspectRatio: '1180 / 450',
              backgroundImage: `url(/images/medicine/diet/case/${slugIndex + 1}-3.png)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                display: 'none',
              }}
              src={`/images/medicine/diet/case/${slugIndex + 1}-3.png`}
              alt="에스본한의원 맞춤 한약의 감량 결과"
            />
          </motion.div>
          <motion.div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '130px',
              paddingLeft: '100px',
              paddingRight: '100px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div
              style={{
                maxWidth: '380px',
                backgroundImage: `url(/images/medicine/diet/case/${slugIndex + 1}-1.png)`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                aspectRatio: '380 / 635',
                position: 'relative',
                flex: 1,
              }}
            >
              <div
                style={{
                  backgroundColor: '#767676',
                  width: '180px',
                  height: '180px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  transform: 'translate(50%, -50%)',
                }}
              >
                <Roboto
                  size="large"
                  style={{
                    color: '#ffffff',
                  }}
                >
                  {data.before}
                </Roboto>
              </div>
            </div>
            <img src="/icons/right-arrow.svg" />

            <div
              style={{
                maxWidth: '380px',
                backgroundImage: `url(/images/medicine/diet/case/${slugIndex + 1}-2.png)`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                aspectRatio: '380 / 635',
                position: 'relative',
                flex: 1,
              }}
            >
              <div
                style={{
                  backgroundColor: '#826144',
                  width: '180px',
                  height: '180px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  transform: 'translate(50%, -50%)',
                }}
              >
                <Roboto
                  size="large"
                  style={{
                    color: '#ffffff',
                  }}
                >
                  {data.after}
                </Roboto>
              </div>
            </div>
          </motion.div>
        </DietSection>
      </DietContainer>
    </>
  );
};

export default DietCasePage;
