import { useEffect } from 'react';
import { getKakaoToken, kakaoLoginWithFirebase } from '../../utils/oauth/kakao';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { browserSessionPersistence, setPersistence } from 'firebase/auth';
import { authService } from '../../utils/firebase/fbInstance';

const Callback = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();
  useEffect(() => {
    if (!code) return;

    (async () => {
      const data = await getKakaoToken(code);
      setPersistence(authService, browserSessionPersistence);
      await kakaoLoginWithFirebase(data);
      navigate('/');
    })();
  }, [navigate, code]);
  return <div>로그인중..</div>;
};

export default Callback;
