import styled from 'styled-components';

export const CheckboxContainer = styled.div``;

export const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0 40px;
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  gap: 10px;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (max-width: 680px) {
    margin: 12px 0 0 0;
  }
`;

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  &[type='checkbox'] {
    display: none;

    &:checked + label:before {
      background-image: url(/icons/checkbox.svg);
    }
  }
`;
