import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSProperties, useRef, useState } from 'react';
import { Roboto } from '../../components/Typography';
import { HospitalizationSwiperSlide } from './styles';

interface HomeSwiperProps {
  className?: string;
  style?: CSSProperties;
}

const roomImages = [
  '/images/hospitalization/slide/image1.jpg',
  '/images/hospitalization/slide/image2.jpg',
  '/images/hospitalization/slide/image3.jpg',
  '/images/hospitalization/slide/image4.jpg',
  '/images/hospitalization/slide/image5.jpg',
  '/images/hospitalization/slide/image6.jpg',
  '/images/hospitalization/slide/image7.jpg',
];

const HospitalizationSwiper = ({ className, style }: HomeSwiperProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  return (
    <div
      style={{
        position: 'relative',
        // marginTop: '40px',
      }}
    >
      <Swiper
        slidesPerView={'auto'}
        breakpoints={{
          1080: {
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 260,
              modifier: 1,
              slideShadows: true,
            },
            spaceBetween: 180,
            centeredSlides: true,
          },
        }}
        loop={true}
        modules={[EffectCoverflow]}
        className={className}
        style={style}
        effect={'coverflow'}
        grabCursor={true}
        ref={swiperRef}
        onSlideChange={(index: any) => {
          setCurrentIndex(index.realIndex);
        }}
        initialSlide={0}
      >
        {roomImages.map((image, index) => (
          <HospitalizationSwiperSlide key={index}>
            <img
              style={{
                width: '100%',
              }}
              src={image}
              alt=""
            />
          </HospitalizationSwiperSlide>
        ))}
      </Swiper>
      <div
        style={{
          display: 'flex',
          gap: '30px',
          height: '64px',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '45px',
        }}
      >
        <button
          onClick={() => {
            swiperRef.current?.swiper.slidePrev();
          }}
          style={{
            padding: '10px',
          }}
        >
          <img src="/icons/button-left.svg" alt="" />
        </button>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            transform: 'translateY(5px)',
          }}
        >
          <Roboto
            style={{
              color: 'white',
              fontWeight: 400,
              fontSize: '22px',
              lineHeight: '25.6px',
            }}
          >
            {(currentIndex + 1).toString().padStart(2, '0')}
          </Roboto>
          <Roboto
            style={{
              color: '#A9A9A9',
              fontWeight: 400,
              fontSize: '22px',
              lineHeight: '25.6px',
            }}
          >
            /
          </Roboto>
          <Roboto
            style={{
              color: '#A9A9A9',
              fontWeight: 400,
              fontSize: '22px',
              lineHeight: '25.6px',
            }}
          >
            {roomImages.length.toString().padStart(2, '0')}
          </Roboto>
        </div>
        <button
          onClick={() => {
            swiperRef.current?.swiper.slideNext();
          }}
          style={{
            padding: '10px',
          }}
        >
          <img src="/icons/button-right.svg" alt="" />
        </button>
      </div>
    </div>
  );
};

export default HospitalizationSwiper;
