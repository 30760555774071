import { CSSProperties, ReactNode } from 'react';
import { Namsan, NotoSerif } from '../Typography';

interface DescriptionProps {
  title?: ReactNode;
  paragraph?: ReactNode;
  style?: CSSProperties;
}

const Description = ({ style, title, paragraph }: DescriptionProps) => {
  return (
    <div style={style}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '24px',
          gap: '8px',
        }}
      >
        <img
          style={{
            width: '40px',
            height: '40px',
            marginRight: '2.5px',
          }}
          src="/icons/check-outlined-brown-light.svg"
          alt=""
        />
        <NotoSerif
          size="large"
          style={{
            // color: '#826144',
            color: '#D2A780',
            lineHeight: '44.8px',
          }}
        >
          {title}
        </NotoSerif>
      </div>
      <Namsan
        style={{
          // color: '#555555',
          color: '#D9D9D9',
          marginTop: '24px',
          lineHeight: '33px',
        }}
      >
        {paragraph}
      </Namsan>
    </div>
  );
};

export default Description;
