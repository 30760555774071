import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: #333333;
  padding-top: 45px;
  padding-bottom: 15px;
  line-height: 24px;
`;

export const FooterBox = styled.div`
  max-width: 1180px;
  margin: 0 auto;
`;

export const FooterLogo = styled.div`
  width: 126px;
  height: 75px;
  margin-bottom: 30px;
  display: block;
  text-indent: -9999px;
  background: url(/icons/footer_logo.png) no-repeat;
  background-size: contain;
`;

export const FooterTextDivider = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`;

export const MobileFooter = styled.footer`
  background-color: #333333;
  @media (max-width: 768px) {
    padding-bottom: calc(100vw / 6);
  }
`;
