import styled from 'styled-components';
import { Container } from '../../components/common';
import { css } from 'styled-components';
import { Pretendard } from '../../components/Typography';
import { HorizontalDivider } from '../../components/Divider';

export const LoginContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;
  align-items: center;
  @media (max-width: 480px) {
    align-items: stretch;
  }
`;

export const LoginButton = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.primary};
  color: #ffffff;
  border-radius: 8px;

  ${({ disabled }) => css`
    opacity: ${disabled ? 0.5 : 1};
  `}

  @media (max-width: 680px) {
    height: 46px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const LoginDivider = styled.span`
  color: #d9d9d9;
  background: #222222;
  padding: 0 20px;
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #5d5d5d;

    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const AuthTitle = styled(Pretendard)`
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;

  @media (max-width: 680px) {
    font-weight: 300;
    font-size: 14px;
    line-height: 22.4px;
  }
`;

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AuthInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  ${HorizontalDivider} {
    margin: 60px 0;
  }
  @media (max-width: 680px) {
    gap: 20px;
    margin-bottom: 60px;

    ${HorizontalDivider} {
      margin: 40px 0;
    }
  }
`;

export const ModalImage = styled.img`
  & path {
    stroke: red;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FindEmailModalTitle = styled(Pretendard)`
  margin: 50px 0 30px;
  font-size: 40px;
  font-weight: 500;
  line-height: 33px;
  color: #333333;

  @media (max-width: 680px) {
    font-size: 24px;
    margin: 20px 0 10px;
  }
`;

export const FindEmailModalParagraph = styled(Pretendard)`
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  color: #767676;
  text-align: center;
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
