import {
  ReactNode,
  InputHTMLAttributes,
  CSSProperties,
  forwardRef,
  ForwardedRef,
  useState,
} from 'react';
import {
  InputContainer,
  StyledInput,
  StyledInputError,
  StyledLabel,
  StyledLabelContainer,
  StyledPasswordEye,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  error?: string;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  errorStyle?: CSSProperties;
  showPasswordEye?: boolean;
}

const Input = (
  { label, type, error, labelStyle, inputStyle, errorStyle, showPasswordEye, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputContainer>
      <StyledLabelContainer>
        {label ? <StyledLabel style={labelStyle}>{label}</StyledLabel> : null}
        <div
          style={{
            flex: 1,
            position: 'relative',
          }}
        >
          <div>
            <StyledInput
              style={inputStyle}
              ref={ref}
              type={showPassword ? 'text' : type}
              {...props}
            />
            {showPasswordEye && (
              <StyledPasswordEye
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                src={`/icons/pwd_eye${showPassword ? '_closed' : ''}.svg`}
              />
            )}
            {error && <StyledInputError style={errorStyle}>*{error}</StyledInputError>}
          </div>
        </div>
      </StyledLabelContainer>
    </InputContainer>
  );
};

export default forwardRef(Input);
