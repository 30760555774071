import { TermsContainer } from './styles';

const Terms = () => {
  return (
    <TermsContainer>
      <p>
        <strong>제 1조 개인정보 수집에 대한 동의</strong>
      </p>
      <div className="">
        <div>
          <p>
            에스본한의원(이하 ‘사이트’) 은(는) 고객은 사이트 회원 가입시 회원 약관, 개인정보 수집 및
            이용 내용에 대해 「동의함」 버튼을 클릭할 수 있는 절차를 마련하여, 「동의함」 버튼을
            클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다.
          </p>
        </div>
        <div>
          <p>
            <strong>제 2조 개인정보의 수집 항목 및 이용 목적</strong>
          </p>
          <p>
            "개인정보"는 생존하는 개인에 관한 정보로서 해당 정보에 포함된 성명, 주민등록번호 등의
            사항으로 해당 개인을 식별할 수 있는 정보(해당 정보만으로는 특정 개인을 식별할 수
            없더라도 다른 정보와 쉽게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
          </p>
          <p>사이트가 고객의 개인정보를 수집 이용하는 목적은 다음과 같습니다.</p>
          <p>일반 회원정보</p>
          <p>
            - 수집시기: 입력폼 작성시
            <br />- 필수 수집항목: 이름, 연락처
            <br />- 이용목적: 서비스 이용시 상담
            <br />- 보유기간: 5년간 보관
          </p>
        </div>
        <div>
          <p>
            <strong>제 3조 쿠키에 의한 개인정보 수집</strong>
          </p>
          <p>① 쿠키의 사용목적</p>
          <p>
            - 개인의 관심 분야에 따라 차별화된 정보를 제공
            <br />- 접속빈도 또는 방문시간 등을 분석하고 이용자의 취향과 관심분야를 파악하여
            타겟(target) 마케팅 및 서비스 개선의 척도로 활용
            <br />- 쇼핑한 품목들에 대한 정보와 관심있게 둘러본 품목을 추적하여 개인 맞춤 쇼핑서비스
            제공
          </p>
          <p>② 쿠키의 운영 및 거부</p>
          <p>
            쿠키는 사용자의 컴퓨터 하드디스크에 저장되며, 사용자의 컴퓨터는 식별하지만 사용자를
            개인적으로 식별하지는 않습니다.
            <br />
            또한 고객은 웹브라우저에 설정을 통해 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다
            확인을 거치도록 할 수 있습니다.
            <br />
            단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용할 수 없습니다.
          </p>
          <p>③ 쿠키 설정 거부 방법</p>
          <p>
            가. Internet Explorer의 경우
            <br />웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 탭 &gt; 직접 설정
          </p>
          <p>
            나. Chrome의 경우
            <br />웹 브라우저 우측 상단의 메뉴 아이콘 선택 &gt; 설정 &gt; 화면 하단의 고급 설정 표시
            &gt;개인정보 섹션의 콘텐츠 설정 버튼 &gt; 쿠키 섹션에서 직접 설정
          </p>
        </div>
        <div>
          <p>
            <strong>제 4조 개인정보의 보유 및 이용기간 및 파기</strong>
          </p>
          <p>
            ① 고객의 개인정보는 회원탈퇴 등 수집 및 이용목적이 달성되거나 동의철회 요청이 있는 경우
            지체없이 파기됩니다.
            <br />
            단,「전자상거래 등에서의 소비자보호에 관한 법률」 등 관련법령의 규정에 의하여 다음과
            같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을
            경우에는 그 기간동안 보유합니다.
          </p>
          <p>
            가. 「전자상거래 등에서의 소비자보호에 관한 법률」 제6조
            <br />- 계약 또는 청약 철회 등에 관한 기록 : 5년
            <br />- 대금결재 및 재화 등의 공급에 관한 기록 : 5년
            <br />- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
          </p>
          <p>
            나. 「통신비밀보호법」 제15조의2
            <br />- 방문(로그)에 관한 기록: 1년
          </p>
          <p>다. 기타 관련 법령 등</p>
          <p>② 사이트의 개인정보 파기방법은 다음과 같습니다.</p>
          <p>
            가. 파기절차
            <br />- 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
            별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 일정기간 저장된 후 파기됩니다.
            <br />- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로
            이용되지 않습니다.
          </p>
          <p>
            나. 파기방법
            <br />- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
            <br />- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
            사용하여 삭제합니다.
          </p>
          <p>
            ③ 사이트는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제29조 제2항에 따라
            휴면회원(최근 12개월 동안 서비스를 이용하지 아니한 회원)에 대해 회원자격 상실에 대한
            안내문을 통지하고, 안내문에서 정한 기한 내에 답변이 없을 경우 회원자격을 상실시킬 수
            있습니다.
          </p>
          <p>
            이 경우, 휴면회원의 개인정보는 다른 회원의 개인정보와 분리하여 별도로 저장·관리 되며,
            분리 저장된 개인정보는 법정보관기간 경과 후 파기하고 있습니다.
            <br />
            파기되지 않은 개인 정보 중 해당 이용자의 요청이 있는 경우 고객 정보는 서비스 이용을
            재개하는 시점에 다시 제공됩니다.
          </p>
        </div>
        <div>
          <p>
            <strong>제 5조 제3자에 대한 제공</strong>
          </p>
          <p>
            ① 사이트는 고객의 개인정보를 "제1조 개인정보의 수집항목 및 이용목적 "에서 고지한 범위를
            넘어 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다.
            <br />② 다음은 예외로 합니다.
            <br />
            가. 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의 요구가 있을 경우
            <br />
            나. 통계작성, 학술연구나 시장조사 등을 위하여 특정 개인을 식별할 수 없는 형태로 광고주,
            협력사나 연구단체 등에 제공하는 경우
            <br />
            다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
          </p>
          <p>
            상기사항에 의해 개인정보를 제공하는 경우에도 본래의 수집 및 이용 목적에 반하여
            무분별하게 정보가 제공되지 않도록 최대한 노력하겠습니다.
          </p>
        </div>
        <div>
          <p>
            <strong>제 6조 개인정보의 열람 및 정정</strong>
          </p>
          <p>
            ① 고객께서는 언제든지 등록된 귀하의 개인정보를 열람하거나 정정하실 수 있습니다.
            개인정보보호책임자 또는 담당자 에게 서면이나 전화 또는 이메일(E-mail)로 열람이나 정정을
            요청하시면 곧바로 조치하겠습니다.
            <br />② 고객께서 개인정보의 오류에 대해 정정을 요청하면, 사이트는 정정을 완료하기 전까지
            해당 개인 정보를 이용하거나 제공하지 않습니다.
            <br />③ 잘못된 개인정보를 제3자에게 이미 제공했을 때에는 정정 처리결과를 제3자에게
            곧바로 통지하여 정정하도록 조치하겠습니다.
          </p>
        </div>
        <div>
          <p>
            <strong>제 7조 개인정보의 안전성 확보 조치</strong>
          </p>
          <p>
            사이트는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및
            물리적 조치를 하고 있습니다.
          </p>
          <p>
            1. 개인정보의 암호화
            <br />
            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며
            중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
            보안기능을 사용하고 있습니다.
          </p>
          <p>
            2. 해킹 등에 대비한 기술적 대책
            <br />
            사이트는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
            보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에
            시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
          </p>
        </div>
        <div>
          <p>
            <strong>제 8조 개인정보보호 책임자</strong>
          </p>
          <p>
            사이트의 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의
            불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </p>
          <p>
            개인정보 보호책임자
            <br />- 성명 : 강인혜
            <br />- 연락처 : 031-357-1897
          </p>
          <p>
            개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 한국정보보호진흥원(KISA)
            개인정보침해신고센터로 문의하시기 바랍니다. 또한, 귀하가 개인정보침해를 통한 금전적,
            정신적 피해를 입으신 경우에는 한국정보보호진흥원*KISA) 개인정보분쟁조정위원회에
            피해구제를 신청하실 수 있습니다.
          </p>
          <p>
            - KISA 개인정보보호 (http://privacy.kisa.or.kr / (국번 없이) 118)
            <br />- 경찰청 사이버안전국 (http://cyberbureau.police.go.kr (국번없이) 182)
            <br />- 대검찰청 사이버수사과 (http://spo.go.kr / 02-3480-3570)
            <br />- 개인정보 침해신고센터 (한국인터넷 진흥원 운영) ( http://privacy.kisa.or.kr /
            (국번없이) 118)
            <br />- 개인정보 분쟁조정위원회 (한국인터넷 진흥원 운영) (http://www.kopico.go.kr /
            1833-6972)
          </p>
        </div>
        <div>
          <p>
            <strong>제 9조 개인정보 처리방침 변경</strong>
          </p>
          <p>
            이 개인정보처리방침은 시행일(2023년 01월 30일)로부터 적용되며, 법령 및 방침에 따른
            변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을
            통하여 고지할 것입니다.
          </p>
        </div>
      </div>
    </TermsContainer>
  );
};

export default Terms;
