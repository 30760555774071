import { MobileImageData } from '../../types/types';

export const caseList = [
  {
    image: '/images/case/image1.png',
    title: '급성 요통',
    subtitle: '요신경총 감작',
    description: (
      <>
        보행이 힘들 정도의 급성 요통이
        <br />
        발생한 환자분의 사례
      </>
    ),
    href: 'https://blog.naver.com/krikkk/223493382715',
  },
  {
    image: '/images/case/image2.jpg',
    title: '안면 신경마비',
    subtitle: '람세이헌트 증후군',
    description: (
      <>
        두통 이후 발생한 안면마비
        <br />
        환자분의 사례
      </>
    ),
    href: 'https://blog.naver.com/krikkk/223485967027',
  },
  {
    image: '/images/case/image3.png',
    title: '고관절 통증',
    subtitle: '고관절 점액낭염',
    description: (
      <>
        필라테스 후 고관절 통증이 심한
        <br />
        40대 여성분의 사례
      </>
    ),
    href: 'https://blog.naver.com/krikkk/223483799468',
  },
  {
    image: '/images/case/image4.png',
    title: '출산 후 손목 통증',
    subtitle: '드퀘르벵 증후군',
    description: (
      <>
        출산 후 손목 통증이 발생한
        <br />
        30대 여성분의 사례
      </>
    ),
    href: 'https://blog.naver.com/krikkk/223474727366',
  },
];

export const mobileImageList = [
  {
    img: '1.jpg',
    href: 'https://blog.naver.com/krikkk/223493382715',
  },
  {
    img: '2.svg',
  },
  {
    img: '3.png',
    href: 'https://blog.naver.com/krikkk/223485967027',
  },
  {
    img: '4.svg',
  },
  {
    img: '5.png',
    href: 'https://blog.naver.com/krikkk/223483799468',
  },
  {
    img: '6.svg',
  },
  {
    img: '7.jpg',
    href: 'https://blog.naver.com/krikkk/223474727366',
  },
  {
    img: '8.svg',
  },
];
