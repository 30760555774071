import styled from 'styled-components';

export const TermsContainer = styled.div`
  max-height: 240px;
  font-size: 14px;
  line-height: 1.4;
  color: #d9d9d9;
  overflow: scroll;
  border: 1px solid #d9d9d9;
  padding: 40px 49px 40px 40px;

  & p strong {
    font-weight: 700;
    display: block;
    margin: 20px 0 10px;
  }

  @media (max-width: 680px) {
    font-size: 10px;
    padding: 20px;
    margin-top: 20px;
  }
`;
