import { Roboto } from '../Typography';
import { QuickMenuContainer, QuickMenuItem } from './styles';
import { Link } from 'react-router-dom';

const menuList = [
  {
    name: '네이버 예약',
    link: 'https://map.naver.com/p/entry/place/1906364731?lng=126.8182219&lat=37.2847227&placePath=%2Fhome&searchType=place&c=15.00,0,0,0,dh',
    image: '/icons/quick-naver.svg',
  },
  {
    name: '카카오톡 상담',
    link: 'https://pf.kakao.com/_PxfxjDK',
    image: '/icons/quick-kakao.svg',
  },
  {
    name: '인스타그램',
    link: 'https://www.instagram.com/chachakmc?igsh=MWllbGpsY203aTllcw==',
    image: '/icons/quick-instagram.svg',
  },
];

const QuickMenu = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <QuickMenuContainer>
      {menuList.map((menu) => (
        <QuickMenuItem key={menu.name}>
          <Link
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 6,
            }}
            to={menu.link}
            target="_blank"
          >
            <img
              style={{
                width: '100%',
              }}
              src={menu.image}
              alt={menu.name}
            />
            {/* {menu.name} */}
          </Link>
        </QuickMenuItem>
      ))}
      <QuickMenuItem
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
        }}
        onClick={scrollToTop}
      >
        <img src="/icons/top-arrow.svg" alt="" />
        <Roboto
          style={{
            color: '#222',
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '15px',
          }}
        >
          TOP
        </Roboto>
      </QuickMenuItem>
    </QuickMenuContainer>
  );
};

export default QuickMenu;
