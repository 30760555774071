import styled from 'styled-components';

interface TableProps {
  $borderColor?: string;
}

const Table = styled.table<TableProps>`
  margin-top: 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  & td {
    line-height: 74px;
    text-align: center;
    // border: 1px solid ${({ theme, $borderColor }) => $borderColor ?? theme.color.white};
    border: 1px solid #222;
  }
`;

export default Table;
