import { CSSProperties, forwardRef } from 'react';

interface PaginationProps {
  style?: CSSProperties;
}

const CustomPagination = forwardRef((props: PaginationProps, ref: any) => {
  return (
    <div
      style={{
        marginTop: '40px',
        ...props.style,
      }}
    >
      <div
        className="swiper-custom-pagination"
        style={{
          width: 'fit-content',
          margin: '0 auto',
        }}
        ref={ref}
      ></div>
    </div>
  );
});

export default CustomPagination;
