import styled from 'styled-components';
import { Section, Container, FullWidthSection } from '../../../components/common';
import List from '../../../components/List';

export const HealthInsuranceContainer = styled(Container)`
  gap: 200px;
`;

export const HealthInsuranceSection = styled(Section)``;

export const FullWidthInsuranceSection = styled(FullWidthSection)`
  background-color: #fafaf8;
  display: flex;
  align-items: center;
`;

export const MobileCoverageList = styled(List)`
  line-height: 36px;
  grid-column-gap: 10px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
    line-height: 43px;
  }
`;
