import styled from 'styled-components';

export const ImageDescriptionBoxContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ImageBox = styled.div<{ $bgUrl?: string }>`
  height: 600px;
  width: 580px;
  background-image: ${({ $bgUrl }) => `url(${$bgUrl})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  & img {
    visibility: hidden;
    width: 0;
    height: 0;
  }
`;

export const DescriptionBox = styled.div``;
