// import { OAuthCredential } from "firebase/auth";
import { OAuthProvider, signInWithCredential } from 'firebase/auth';
import { authService } from '../firebase/fbInstance';

const provider = new OAuthProvider('oidc.kakao');
const restKey = process.env.REACT_APP_KAKAO_REST_API_KEY;
const redirectURI = process.env.REACT_APP_KAKAO_REDIRECT_URI;
const kakaoSecret = process.env.REACT_APP_KAKAO_SECRET;
// const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${restKey}&redirect_uri=${redirectURI}`;
// const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${restKey}`;
const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${restKey}&redirect_uri=${redirectURI}`;

export const kakaoLogin = () => {
  window.location.href = KAKAO_AUTH_URL;
};

// https://reliable-creator.firebaseapp.com/__/auth/handler

export const getKakaoToken = async (code: string) => {
  const response = await fetch('https://kauth.kakao.com/oauth/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=authorization_code&client_id=${restKey}&redirect_uri=${window.location.origin}/callback/kakao&code=${code}&client_secret=${kakaoSecret}`,
  });
  const data = await response.json();

  // idToken이 있으면 진행, 없으면 홈으로,
  // 만약 accessToken이 있다면
  //     window.Kakao.Auth.setAccessToken(data.access_token);

  return data;
};

export const kakaoLoginWithFirebase = async (data: any) => {
  const credential = provider.credential({
    idToken: data.id_token,
  });

  const result = await signInWithCredential(authService, credential);
  return OAuthProvider.credentialFromResult(result);

  // return data;
  // if (data.id_token) {
  //   setIdToken(data.id_token);
  // } else {
  //   navigate("/");
  // }
  // if (data.access_token) {
  //     setAccessToken(data.access_token);
  //     window.Kakao.Auth.setAccessToken(data.access_token);
  // }
};
// https://reliable-creator.firebaseapp.com/__/auth/handler
