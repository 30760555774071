import styled from 'styled-components';

interface FullWidthSectionProps {
  color?: 'light' | 'dark';
}

export const TherapyContainer = styled.div`
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 200px;
`;

export const MobileTherapyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 100px 0;
`;

export const MobileTherapySection = styled.div`
  padding: 0 16px;
`;

export const TherapySection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1180px;
  margin: 0 auto;
`;

export const FullWidthTherapySection = styled.section<FullWidthSectionProps>`
  height: 100vh;
  background-color: ${({ theme, color }) =>
    color === 'dark' ? theme.color.dark : theme.color.white};
  // padding-top: 140px;
  // padding-bottom: 140px;
`;
