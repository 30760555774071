import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
  max-width: 550px;
  width: 100%;
  aspect-ratio: 1 / 1;

  @media (max-width: 680px) {
    max-width: 368px;
    max-height: 400px;
  }
`;

export const ModalClose = styled.button`
  position: absolute;
  top: 50px;
  right: 50px;
  border: none;
  width: 50px;
  height: 50px;
  background: transparent;
  font-size: 20px;
`;
