import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSProperties, useRef, useState } from 'react';
import { Roboto } from '../../components/Typography';
import { HospitalizationSwiperSlide } from './styles';
import CustomPagination from '../../components/CustomPagination';

interface HomeSwiperProps {
  className?: string;
  style?: CSSProperties;
}

const roomImages = [
  '/images/hospitalization/slide/image1.jpg',
  '/images/hospitalization/slide/image2.jpg',
  '/images/hospitalization/slide/image3.jpg',
  '/images/hospitalization/slide/image4.jpg',
  '/images/hospitalization/slide/image5.jpg',
  '/images/hospitalization/slide/image6.jpg',
  '/images/hospitalization/slide/image7.jpg',
];

const HospitalizationProcessSwiper = ({ className, style }: HomeSwiperProps) => {
  const paginationRef = useRef(null);
  const pagination = {
    el: paginationRef.current,
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        loop={true}
        style={style}
        //  grabCursor={true}
        modules={[Pagination]}
        pagination={pagination}
      >
        <HospitalizationSwiperSlide>
          <img
            style={{
              width: '100%',
            }}
            src={'/responsive/hospitalization/slides/1.svg'}
            alt=""
          />
        </HospitalizationSwiperSlide>
        <HospitalizationSwiperSlide>
          <img
            style={{
              width: '100%',
            }}
            src={'/responsive/hospitalization/slides/2.svg'}
            alt=""
          />
        </HospitalizationSwiperSlide>
        <HospitalizationSwiperSlide>
          <img
            style={{
              width: '100%',
            }}
            src={'/responsive/hospitalization/slides/3.svg'}
            alt=""
          />
        </HospitalizationSwiperSlide>
      </Swiper>
      <CustomPagination
        style={{
          marginTop: '20px',
        }}
        ref={paginationRef}
      />
    </>
  );
};

export default HospitalizationProcessSwiper;
