import styled from 'styled-components';

export const ButtonWrapper = styled.button<{
  $hasRightArrow?: boolean;
}>`
  background-color: ${({ theme }) => theme.color.primary};
  width: 109px;
  height: 50px;
  border-radius: 25px;
  color: ${({ theme }) => theme.color.white};
  &:after {
    content: '';
    display: ${({ $hasRightArrow }) => ($hasRightArrow ? 'inline-block' : 'none')};
    margin-left: 10px;
    background-image: url('/icons/chevron-right.svg');
    background-repeat: no-repeat;
    width: 6px;
    height: 12px;
  }
`;
