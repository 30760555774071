import styled from 'styled-components';

interface FullWidthSectionProps {
  color?: 'light' | 'dark';
}

export const AcupunctureContainer = styled.div`
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 200px;
`;

export const AcupunctureSection = styled.section`
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const FullWidthAcupunctureSection = styled.section<FullWidthSectionProps>`
  height: calc(100vh - 90px);
  background-color: ${({ theme, color }) =>
    color === 'dark' ? theme.color.dark : theme.color.white};
`;

export const AcupunctureOrder = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.primary};
  color: #ffffff;
  flex-shrink: 0;
`;

export const AcupunctureTable = styled.table`
  margin-top: 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  & td {
    line-height: 74px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.color.dark};
  }
`;

export const AcupunctureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 920px;
  margin-top: 50px;
`;

export const AcupunctureGridItem = styled.div`
  max-width: 280px;
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  border: 0.5px solid ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  padding-top: 68px;

  text-align: center;
  position: relative;
  &:before {
    font-family: 'Roboto', sans-serif;
    color: ${({ theme }) => theme.color.white};
    content: '0' attr(data-order);
    display: block;
    margin-bottom: 20px;
  }
`;

export const MobileAcupunctureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 100px 0;
`;

export const MobileAcupunctureSection = styled.div`
  padding: 0 16px;
`;

export const VideoThumbnail = styled.div<{ $isPlaying?: boolean }>`
  display: ${({ $isPlaying }) => ($isPlaying ? 'none' : 'flex')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-image: url(/images/spine/acupuncture/thumbnail.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;

  @media (max-width: 1080px) {
    background-image: url(/images/spine/acupuncture/thumbnail-mobile.png);
  }
`;
