import styled from 'styled-components';

export const QuickMenuContainer = styled.div`
  position: fixed;
  bottom: 95px;
  right: 30px;
  height: fit-content;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: row;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    gap: 0;
    border: 1px solid #d9d9d9;
    border-left: none;
  }
`;

export const QuickMenuItem = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 15px 0px #00000026;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0;
    box-shadow: none;

    border-left: 1px solid #d9d9d9;
  }
`;
