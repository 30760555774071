import React, { useState } from 'react';
import PageTitle from '../../components/PageTitle';
import {
  DizzyCauseList,
  DizzyContainer,
  DizzySection,
  DizzySymptomList,
  FullWidthDizzySection,
} from './styles';
import Card from '../../components/Card';
import { NotoSerif, Pretendard } from '../../components/Typography';
import { dizzyCauses, dizzySymptoms, dizzyTreatments } from './data';
import ImageDescriptionBox from '../../components/ImageDescriptionBox';
import Button from '../../components/Button';
import { Helmet } from 'react-helmet';

const getCauseIconUrl = (index: number, selectedIndex: number) => {
  return `/images/dizzy/causes/icon${index + 1}${selectedIndex === index ? '-selected' : ''}.png`;
};

const DizzyPage = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 어지럼증</title>
      </Helmet>
      <PageTitle title="어지럼증" />
      <DizzyContainer>
        <DizzySection>
          <Card
            containerStyle={{
              marginBottom: '30px',
            }}
            title="365일 척추관절 중점진료 에스본한의원"
            paragraph="도대체 원인이 무엇인가요?"
            position="center"
          />
          <Pretendard
            style={{
              color: '#555555',
              textAlign: 'center',
              lineHeight: '33px',
              fontSize: '20px',
            }}
          >
            속이 메스껍고 머리가 너무 어지러워요..
            <br />
            병원에서 정밀 검사를 받아 보았지만 정상이라고 합니다..
          </Pretendard>
          <div
            style={{
              maxWidth: '1180px',
              marginTop: '60px',
              width: '100%',
              aspectRatio: '980 / 500',
              backgroundImage: 'url(/images/dizzy/image1.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <img
              style={{
                display: 'none',
              }}
              src="/images/spine/therapy/image5.png"
              alt="추나요법 건강보험 적용"
            />
          </div>
        </DizzySection>
        <DizzySection>
          <Card title="365일 척추관절 중점진료 에스본한의원" paragraph="어지럼증 주요 증상" />
          <DizzySymptomList
            style={{
              gridTemplateColumns: 'repeat(3, 1fr)',
              marginTop: '50px',
            }}
          >
            {dizzySymptoms.map((symptom, index) => (
              <li key={index}>
                <div
                  style={{
                    aspectRatio: '380 / 300',
                    width: '100%',
                    backgroundColor: '#FAFAF8',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '63%',
                      aspectRatio: '1 / 1',
                    }}
                    src={`/images/dizzy/symptoms/image${index + 1}.png`}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  <NotoSerif
                    style={{
                      lineHeight: '44.8px',
                      fontSize: '28px',
                      fontWeight: '500',
                    }}
                  >
                    {symptom.content}
                  </NotoSerif>
                </div>
              </li>
            ))}
          </DizzySymptomList>
        </DizzySection>
        <DizzySection>
          <Card
            containerStyle={{
              marginBottom: '30px',
            }}
            title="365일 척추관절 중점진료 에스본한의원"
            paragraph="어지럼증의 4가지 원인"
            position="center"
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: '100px',
            }}
          >
            <DizzyCauseList>
              {dizzyCauses.map((cause, index) => (
                <li
                  key={index}
                  style={{
                    backgroundColor: selectedIndex === index ? '#826144' : '#FAFAF8',
                    paddingTop: '30px',
                    paddingBottom: '20px',
                    borderRadius: '15px',
                  }}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                >
                  <div
                    style={{
                      width: '40%',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                      }}
                      src={getCauseIconUrl(index, selectedIndex)}
                      alt=""
                    />
                  </div>
                  <div>
                    <NotoSerif
                      style={{
                        color: selectedIndex === index ? '#ffffff' : '#333333',
                      }}
                    >
                      {cause.category}
                    </NotoSerif>
                  </div>
                </li>
              ))}
            </DizzyCauseList>
            <div
              style={{
                marginTop: '100px',
              }}
            >
              <NotoSerif
                style={{
                  display: 'block',
                  marginBottom: '30px',
                  lineHeight: '44.8px',
                }}
              >
                {dizzyCauses[selectedIndex].title}
              </NotoSerif>
              <Pretendard
                style={{
                  lineHeight: '33px',
                  fontSize: '20px',
                  letterSpacing: '-0.02em',
                }}
              >
                {dizzyCauses[selectedIndex].content}
              </Pretendard>
            </div>
          </div>
        </DizzySection>
        <FullWidthDizzySection
          color="dark"
          style={{
            width: '100%',
            padding: '140px',
          }}
        >
          <DizzySection>
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              position="center"
              paragraph={
                <NotoSerif
                  style={{
                    fontWeight: 300,
                    fontSize: '40px',
                    lineHeight: '64px',
                    color: '#ffffff',
                  }}
                >
                  원인이 불분명한 어지럼증
                  <br />
                  <NotoSerif
                    as="strong"
                    $isPrimary
                    style={{
                      fontWeight: 300,
                      fontSize: '40px',
                      lineHeight: '64px',
                    }}
                  >
                    치료법은 따로 있습니다
                  </NotoSerif>
                </NotoSerif>
              }
            />
            {dizzyTreatments.map((treatment, index) => (
              <div
                key={index}
                style={{
                  marginTop: '100px',
                  width: '100%',
                }}
              >
                <Button
                  style={{
                    width: '156px',
                    height: '50px',
                    borderRadius: '25px',
                    fontSize: '24px',
                    lineHeight: '40px',
                    fontWeight: 500,
                    cursor: 'text',
                  }}
                >
                  POINT 0{index + 1}
                </Button>
                <NotoSerif
                  size="large"
                  style={{
                    color: '#ffffff',
                    fontWeight: 500,
                    lineHeight: '44.8px',
                    display: 'block',
                    marginTop: '10px',
                    marginBottom: '50px',
                  }}
                >
                  {treatment.title}
                </NotoSerif>
                <div
                  style={{
                    width: '100%',
                    height: '500px',
                    backgroundColor: '#826144',
                  }}
                ></div>
              </div>
            ))}
          </DizzySection>
        </FullWidthDizzySection>
        <DizzySection>
          <ImageDescriptionBox
            src="/images/dizzy/image4.png"
            containerStyle={{
              gap: '120px',
            }}
            descriptionStyle={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            description={
              <>
                <Card
                  title="365일 척추관절 중점진료 에스본한의원"
                  paragraph={
                    <>
                      어지럼증?
                      <br />
                      누구나 한 번쯤 겪는
                      <br />
                      흔한 증상 아니야?
                    </>
                  }
                />
                <Pretendard
                  style={{
                    fontSize: '20px',
                    lineHeight: '33px',
                    color: '#333333',
                    marginTop: '50px',
                    marginBottom: '20px',
                    display: 'block',
                  }}
                >
                  사소한 증상이라도 누군가에게는
                  <br />
                  하루하루를 버텨내야 할 큰 고통일 수 있습니다.
                </Pretendard>
                <Pretendard
                  style={{
                    fontSize: '20px',
                    lineHeight: '33px',
                    color: '#333333',
                  }}
                >
                  환자분의 몸과 마음의 균형을 되찾고
                  <br />
                  다시 안정된 일상을 누릴 수 있도록,
                  <br />
                  에스본한의원이 함께 하겠습니다.
                </Pretendard>
              </>
            }
          />
        </DizzySection>
      </DizzyContainer>
    </>
  );
};

export default DizzyPage;
