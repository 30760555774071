import styled, { css } from 'styled-components';
import { Pretendard } from '../Typography';
import { VerticalDivider } from '../Divider';

interface NavbarProps {
  $isOpen?: boolean;
}

export const NavContainer = styled.div<NavbarProps>`
  max-width: 1180px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  margin-left: calc((100% - 1180px) / 2);
  margin-right: 135px;
  transition: all 0.3s ease;
  & ${Pretendard} {
    color: #ffffff;
  }

  @media (max-width: 1180px) {
    padding: 0 16px;
    margin: 0;
  }

  @media (max-width: 1000px) {
    height: 65px;
    & ${Pretendard} {
      color: #333333;
    }
    ${({ $isOpen }) =>
      $isOpen &&
      css`
        background: #333;
      `};
  }

  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 0.5px;
    bottom: 0;
    left: 0;
    background-color: #ffffff33;
  }
`;

export const Logo = styled.h1`
  height: 58px;
  flex: 1;
  & a {
    display: block;
    width: 100px;
    /* height: 49px; */
    height: 100%;
    text-indent: -9999px;
    background-image: url(/icons/logo.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    & a {
      width: 65px;
      height: 32px;
      background-image: url(/icons/logo-mobile.png);
    }
  }
`;

export const NavListWrapper = styled.div<NavbarProps>`
  position: relative;
  display: flex;
  gap: 60px;

  @media (max-width: 1180px) {
    gap: 30px;
  }

  @media (max-width: 1000px) {
    padding-bottom: 65px;
    position: fixed;
    flex-direction: column;
    right: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    transition: right 0.4s ease;
    gap: 0;
  }
`;

export const MenuIcon = styled.h2<{ $isOpen?: boolean }>`
  display: none;
  width: 50px;
  height: 50px;
  margin-right: -7px;
  text-indent: -9999px;
  background-image: ${({ $isOpen }) =>
    $isOpen ? 'url(/icons/close.svg)' : 'url(/icons/menu.svg)'};
  background-repeat: no-repeat;
  background-size: 36px;
  background-position: center;
  transition: all 0.2s ease;
  cursor: pointer;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const NavbarSubMenu = styled.ul`
  min-width: 160px;
  background-color: #ffffff;
  position: absolute;
  display: none;
  margin-top: 35px;
  transition: all 0.3s ease;
  padding: 20px;
  border-radius: 8px;

  @starting-style {
    opacity: 0;
    height: 10px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    position: static;
    display: block;
    padding: 0;
    height: 0;
    box-shadow: none;
    margin-top: 0;
  }
`;

export const NavListItem = styled.li<{ $subMenuCount?: number; $isSelected?: boolean }>`
  position: relative;
  padding: 60px 30px;
  &:last-child {
    padding-right: 0;
  }
  display: flex;
  justify-content: center;

  &:hover ${NavbarSubMenu} {
    display: block;
  }

  & > a {
    & > ${Pretendard} {
      font-weight: 500;
      font-size: 16px;

      @media (max-width: 680px) {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1000px) {
    align-self: stretch;
    overflow: hidden;
    border-top: 1px solid #e0e0e0;
    padding: 0;
    flex-direction: column;
    & > a {
      display: block;
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      /* &:after {
        display: ${({ $subMenuCount }) => ($subMenuCount ? 'block' : 'none')};
        position: absolute;
        right: 20px;
        top: 20px;
        content: '';
        width: 12px;
        height: 12px;
        background-image: url(/icons/plus.svg);
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center;
      } */
      &:hover {
        background: #f6f6f6;
      }
    }
    ${({ $isSelected, $subMenuCount = 0 }) =>
      $isSelected &&
      css`
        & ${NavbarSubMenu} {
          height: ${$subMenuCount * 50}px;
        }
        & > a:after {
          background-image: url(/icons/minus.svg);
        }
      `}
  }
`;

export const NavSubListItem = styled.li`
  height: 50px;
  line-height: 50px;
  color: #9e9e9e;

  & > a {
    width: 100%;
    height: 100%;
    display: block;
    &:hover {
      color: #333;
      ${Pretendard} {
        color: #191919;
      }
    }
    & > ${Pretendard} {
      color: #999999;
      line-height: 33px;
      font-size: 16px;
      font-weight: 400;

      @media (max-width: 680px) {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    color: #333;
    background: #f6f6f6;
    padding-left: 20px;
  }
`;

export const NavUtil = styled.div`
  position: absolute;
  right: -100px;
  top: 50%;
  transform: translate(100%, -50%);
  padding: 0;
  display: flex;
  align-items: center;

  @media (max-width: 1800px) {
    right: -50px;
  }

  @media (max-width: 1580px) {
    position: static;
    transform: translateX(0);
  }

  @media (max-width: 1000px) {
    margin: 60px 16px 0 0;
    justify-content: flex-end;
    & a:not(:first-child),
    & div[role='separator'] {
      display: none;
    }

    & a:first-child {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 17px;
        margin-right: 5px;
        background-image: url(/icons/login.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    & ${Pretendard} {
      font-size: 12px;
      font-weight: 700;
      line-height: 22.4px;
    }

    /* flex-direction: column;
    align-items: center;
    & div[role='separator'] {
      display: none;
    }

    & > a {
      align-self: stretch;
      border-top: 1px solid #e0e0e0;
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      &:hover {
        background: #f6f6f6;
      }

      & ${Pretendard} {
        font-size: 18px;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
      }
    } */
  }
`;

export const NavUtilVerticalDivider = styled(VerticalDivider)`
  width: 1px;
  margin: 0 30px;
  height: 12px;
  align-self: center;
`;
